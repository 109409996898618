
.Dropdown {
  cursor: pointer;
  font-size: 20px;
  text-align: center;
  font-family: "Trocchi";
}
.Dropdown div {
  box-sizing: border-box!important;
} 
.Dropdown-input {
  box-sizing: border-box;
  border: 1px solid #a6a6a6;
  background: #f8f8f8;
  height: 32px;
  padding: 0 8px;
  display: inline-block;
  max-width: 100%;
  caret-color: transparent;
  overflow: hidden;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 23px;
}

.Dropdown-input:hover {
  border: 1px solid #000000;
}

.Dropdown-input:focus {
  /* border: 1px solid #0078d4; */
  outline: none;
}

.Dropdown-input:invalid {
  border: 1px solid red;
  outline: none;
}

.Dropdown-option {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  padding: 10px 5px;/*  */
  box-sizing: border-box;
  position: relative;
}

.Dropdown-option:hover {
  user-select: none;
}

@keyframes dropDown {
  0% {
    max-height: 0;
  }
  1% {
    max-height: 0;
  }
  100% {
    max-height: inherit;
  }
}

.Dropdown {
  position: absolute;
  top:0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
}

.Dropdown-innerContainer {
  width: 100%;
  position: relative;
}

.Dropdown-label {
  text-align: left;
  margin-bottom: 4px;
  letter-spacing: 0.5px;
  color: #1d5ab9;
}

.Dropdown-label.required::after {
  content: '*';
  color: red;
  margin-left: 2px;
}

.Dropdown-optionsContainer {
  width: 100%;
  position: absolute;
  z-index: 10;
  max-height: 190px;
  overflow: auto;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1), 0px 0px 8px rgba(0, 0, 0, 0.05);
  border: 4px solid white;
  background-color: white;
  scrollbar-color: #262626 transparent;
  box-sizing: border-box;
}
.Dropdown-optionsContainer::-webkit-scrollbar {
  background-color: transparent;
  width: 10px;
}
.Dropdown-optionsContainer::-webkit-scrollbar-track {
  background-color: transparent;
}
.Dropdown-optionsContainer::-webkit-scrollbar-thumb {
  background-color: #262626 !important;
}

.dropdownContainer {
  position: relative;
  box-sizing: border-box;
}

@media only screen and (max-width: 1400px) {
  .Dropdown {
    font-size: 13px;
  }
}


