
.product_promotion_icon_cont {
    display: inline-block;
    width: 1.3vw;
    height: 1.3vw;
    border-radius: 25px;
    font-size: 0.72vw;
    color: black;
    background-color: white;
    font-family: "Trocchi2";
    line-height: 1.3vw;
    text-align: left;
    padding-left: 0.08vw;
    margin-right: 0.72vw;
    position: relative;
    top: -0.3vw;
    font-weight: bold;
}

@media only screen and (max-width: 1024px) {
    .product_promotion_icon_cont {
        width: 16.66px;
        height: 16.66px;
        line-height: 15.5px;
        border-radius: 25px;
        margin-right: 10px;
        font-size: 11px;
        background-color: white;
        top: -2px;
        
    }
}