.___modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999;
  overflow: auto;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.7);

  outline: none;
  user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;

  display: flex;
  justify-content: center;
  align-items: center;
  transition: height 0.1s;
}
.___modal > div {
  display: none;
}
.___modal > div:first-child {
  display: initial;
}
@media only screen and (max-height: 700px) {
  .___modal {
    flex-wrap: wrap;
  }
}

/*pop content program */
.popup_program_title__ {
    font-size: 3.125vw;
    line-height: 4vw;
    margin-bottom: 3.125vw;
    width: 100%;
}
.popup_program_list_item__ {
  font-size: 2.08vw;
  margin-bottom: 1.56vw;
  line-height: 4vw;
}
.popup_program_list_item__ > div:first-child {
  width: 100%;
  line-height: 40px;
}
/*end pop content program */
.popup_plain_text_tile__ {
    font-size: 2.5vw;
    line-height: 4vw;
    width: 100%;
    padding: 0 5vw;
    box-sizing: border-box;
}

@media only screen and (max-width: 1024px) {
  /*pop content program */
  .popup_program_title__ {
    font-size: 22px;
    line-height: unset;
    margin-bottom: 35px;
    padding: 0 10px;
}
  .popup_program_list_item__ {
    font-size: 18px;
    margin-bottom: 15px;
  }
  .popup_program_list_item__ > div:first-child {
    line-height: 28px;
  }
  /*end pop content program */

  .popup_plain_text_tile__ {
    font-size: 22px;
    line-height: unset;
    margin-bottom: 35px;
    padding: 0 20px;
}
}
