.emoticon-container {
  cursor: pointer;
  .emoticon-svg-container {
    margin-bottom: 0.5vw;
    svg {
      width: 3.43vw;
      height: 3.43vw;
    }
  }
  .emoticon-title {
    font-size: 1.04vw;
    line-height: 1.4vw;
    width: 9.45vw;
    text-align: center;
  }
}
@media screen and (max-width: 1024px) {
  .emoticon-container {
    margin-bottom: 20px;
    .emoticon-svg-container {
      margin-bottom: 0;
      svg {
        width: 40px;
        height: 40px;
      }
    }
    .emoticon-title {
      font-size: 12px;
      line-height: 20px;
      width: 155px;
    }
  }
}
