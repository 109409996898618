.check_box_container__ {
  display: flex;
  margin-bottom: 10px;
  align-items: flex-start;
  cursor: pointer;
  outline: none;
  user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
}
.checkbox_disabled {
}
.check_box_square__ {
  width: 16px;
  height: 16px;
  box-sizing: border-box !important;
  background-color: transparent;
  border: 2px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  font-size: 20px;
}
.checkbox_disabled {
  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";

  /* IE 5-7 */
  filter: alpha(opacity=40);

  /* Netscape */
  -moz-opacity: 0.4;

  /* Safari 1.x */
  -khtml-opacity: 0.4;

  /* Good browsers */
  opacity: 0.4;
}
.check_box_square__ > svg {
  visibility: hidden;
  color: #1d1d1d;
  width: 10px;
  height: 9px;
}
.check_box_label__ {
  font-family: "Trocchi";
  font-size: 16px;
}
.check_box_square_checked__ > svg {
  visibility: visible;
}

.checkbox-with-input {
    position: relative;
  .check_box_input_container {
    position: absolute;
    left: 20px;
    top: -3px;
    input {
      border: none;
      background-color: transparent;
      line-height: 5px;
      max-width: 40px;
      margin-right: 5px;
      padding: 0 0;
      margin-left: 5px;
      outline: none;
      color: white;
      font-size: 16px;
      text-align: center;
      font-family: "Trocchi";
      border-bottom: 4px solid #bfbc8e;
      border-top-width: 0;
      border-left-width: 0;

      -webkit-tap-highlight-color: transparent;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      border-right-width: 0;
      outline: none;


    }
  }
}

@media only screen and (max-width: 1400px) {
  .check_box_square__ {
    width: 16px;
    height: 16px;
  }
  .check_box_label__ {
    font-size: 14px;
  }
  .check_box_square__ > svg {
    width: 10px !important;
    height: 9px !important;
  }
  .check_box_input_container {
    input {
        max-width: 25px;
        }
    }
}
