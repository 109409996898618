@font-face {
  font-family: "Cormorant SemiBold";
  src: url("./assets/fonts/Cormorant-SemiBold.ttf") format("woff2"),
    url("./assets/fonts/Cormorant-SemiBold.ttf") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Trocchi";
  src: url("./assets/fonts/Trocchi-Regular.ttf") format("woff2"),
    url("./assets/fonts/Trocchi-Regular.ttf") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Trocchi2';
  src: url('./assets/fonts/TrocchiBoldObliqueStencil.ttf') format('woff2'),
      url('./assets/fonts/TrocchiBoldObliqueStencil.ttf') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Work Sans Bold";
  src: url("./assets/fonts/WorkSans-Bold.ttf") format("woff2"),
    url("./assets/fonts/WorkSans-Bold.ttf") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat-Medium";
  src: url("./assets/fonts/Montserrat-Medium.ttf") format("woff2"),
    url("./assets/fonts/Montserrat-Medium.ttf") format("woff");
  font-weight: normal;
  font-style: normal;
}
.logo_restaurant_cm {
  width: 6.40625vw !important;
}
#section1 {
  background-position: center;
  background-size: cover;
}
.ui-datepicker-current-day {
  pointer-events: none !important;
}

.linie_meniu {
  display: flex;
  margin: 0 auto;
  margin-bottom: 1.09375vw;
  width: 26.04167vw;
}

.info_location {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.info_location > a {
  margin-top: 2vw;
}
.first-screen-last-section-cont {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;;
}
.info_location img {
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
  margin: 0 auto;
  margin-bottom: 1.09375vw;
  width: 26.04167vw;
}

.name_of_loc p {
  font-family: "Cormorant SemiBold";
  color: #ffffff;
  font-size: 1.875vw;
  margin-top: 0.20833333333333334vw;
  margin-bottom: 1.09375vw;
}

.name_of_loc {
  text-align: center;
  display: none !important;
}

.meniu_principal a {
  font-family: "Trocchi";
  font-size: 1.1458333333333335vw;
  color: #ffffff;
  text-decoration: none;
  padding-left: 2.3958333333333335vw;
  outline: none;
}

.meniu_principal a:first-child {
  padding-left: 0vw;
}

.meniu_principal {
  text-align: center;
}

.description h1 {
  font-family: "Trocchi";
  font-size: 3.6458333333333335vw;
  color: #ffffff;
  font-weight: 100;
  line-height: 4.427083333333334vw;
  padding-top: 1.6666666666666667vw;
  margin-top: 20%;
}

.description {
  width: 38.020833333333336vw;
  text-align: center;
  margin: 0 auto;
}

.rezerva a {
  font-family: "Trocchi";
  font-size: 1.25vw;
  color: #ffffff;
  font-weight: 100;
  text-decoration: none;
  box-shadow: 0px 15px 25px 0px rgba(1, 1, 1, 0.15);
  border: 0.2604166666666667vw solid #bfbc8e;
  border-radius: 2.0833333333333335vw;
  background-color: rgba(0, 0, 0, 0.7);
  padding-left: 4.427083333333334vw;
  padding-right: 4.479166666666667vw;
  padding-top: 1.0416666666666667vw;
  padding-bottom: 1.1458333333333335vw;
  line-height: 2.03125vw;
  outline: none;
}

.rezerva {
  text-align: center;
}

.swiper-container {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  text-align: center;
  font-size: 0.9375vw;
  background: #fff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.slider_section2 {
  width: 49.85%;
  position: relative;
  float: left;
}

.your-class div {
  background-repeat: no-repeat;
  height: 100vh;
  background-size: cover;
  background-position: center;
}

button.slick-next.slick-arrow {
  background: url(./assets/img/right.png);
  background-repeat: no-repeat;
  position: absolute;
  top: 46%;
  right: 0.20833333333333334vw;
  z-index: 999;
  outline: none;
  height: 105px;
  width: 55px;
  outline: none;
  border: none;
  cursor: pointer;
  color: transparent;
}
button.slick-prev.slick-arrow {
  background: url(./assets/img/left.png);
  background-repeat: no-repeat;
  position: absolute;
  top: 46%;
  left: 3.1770833333333335vw;
  z-index: 999;
  outline: none;
  height: 105px;
  width: 55px;
  outline: none;
  border: none;
  cursor: pointer;
  color: transparent;
}

#section2 {
  background: url(./assets/img/background.png);
  background-repeat: no-repeat;
  background-repeat: repeat-y;
}

.titlu_restaurant h1 {
  font-family: "Trocchi";
  font-size: 3.6458333333333335vw;
  color: #bfbc8e;
  font-weight: 100;
  line-height: 4.427083333333334vw;
  margin-bottom: 1.1458333333333335vw;
}

.citat_text p {
  font-family: "Trocchi";
  font-size: 1.7708333333333335vw;
  color: #bfbc8e;
  margin-top: 0.3125vw;
  padding-left: 0.2604166666666667vw;
  line-height: 2.5520833333333335vw;
}

.button_vezi_meniu a {
  font-family: "Trocchi";
  font-size: 1.25vw;
  color: #ffffff;
  text-decoration: none;
  outline: none;
  border: 0.2604166666666667vw solid #bfbc8e;
  border-radius: 2.0833333333333335vw;
  padding-left: 3.125vw;
  padding-right: 3.125vw;
  padding-top: 1.0416666666666667vw;
  padding-bottom: 1.0416666666666667vw;
  box-shadow: 0px 15px 25px 0px rgba(1, 1, 1, 0.15);
  outline: none;
  white-space: nowrap;
}

.rezerva_section2 a {
  font-family: "Trocchi";
  font-size: 2.291666666666667vw;
  color: #ffffff;
  text-decoration: none;
  outline: none;
  background-repeat: no-repeat;
  padding-left: 2.7083333333333335vw;
  outline: none;
  background-size: 30px;
  background-position: center left 10px;
}

.descriere_section2 {
  width: 50%;
  float: right;
  position: relative;
  min-height: 100% !important;
  height: 100%;
  display: flex;
  align-items: center;
}

.content_section2 {
  padding-right: 34%;
  padding-top: 13%;
  padding-left: 13%;
  position: relative;
  height: 100%;
}
.contact_info svg:not(.contact_info_svg) {
  width: 40px;
}

.ghilimele_jos {
  text-align: right;
  margin: -30px 0 20px 0;
  width: 100%;
}



.semibold_text_formular_ok {
  font-weight: 500;
}

.svg_item {
  display: flex;
  align-items: center;
  padding-left: 0 !important;
}
.ghilimele img {
  padding-left: 0.2604166666666667vw;
}

.button_vezi_meniu {
  padding-top: 1.4583333333333335vw;
  padding-left: 0.20833333333333334vw;
  display: inline-block;
}

.svg_item svg {
  width: 30px;
  margin-right: 10px;
}

.rezerva_section2 {
  /* position: absolute; */
  bottom: 16%;
  /* Andrei */
  margin-top: 5vh;
  margin-right: -6%;
}

#section3,
#section_command,
.command_receipt_container,
.command_category_navbar,
._payment_modal {
  background: url(./assets/img/background.png);
  background-repeat: repeat-y;
}

.content_section3 {
  margin: 2.03125vw;
  border: 0.2604166666666667vw solid #bfbc8e;
  /*padding: 1.6666666666666667vw;*/
  padding: 2.2666666666666667vw;
  /*padding-bottom: 3.21vw;*/
  min-height: 84vh;
}

.slick-prev:before,
.slick-next:before {
  content: "" !important;
}

.slick-slide img {
  background-repeat: no-repeat;
  height: 100vh;
  background-size: cover;
  background-position: center;
  width: 100%;
  object-fit: cover;
}

.react-datepicker__input-container input {
  /* background-image: url(./assets/img/calendar_slim.png) !important; */
  background-repeat: no-repeat !important;
  background-position: center right 20px !important;
  line-height: 1vw !important;
}

.react-datepicker div {
  padding-bottom: 0 !important;
}

.react-datepicker__day-names,
.react-datepicker__week,
.react-datepicker__day {
  padding-bottom: 0 !important;
}

.social_section {
  display: flex;
}

.social_section a {
  margin-right: 20px;
}

.social_section > a > .svg_img_social_media {
  width: 2.621vw;
  height: auto;
}

/**/
#date-picker {
  display: inline-block;
}
.other-month {
  visibility: hidden;
}
.arrow {
  cursor: pointer;
}
.arrow:hover {
  color: steelblue;
  font-weight: 400;
}
.year,
.month {
  height: 1.2em;
}
.left {
  float: left;
}

.luna_selectat .left {
  padding-right: 0.9895833333333334vw;
  padding-top: 0.2604166666666667vw;
}

.right {
  float: right;
}

.luna_selectat .right {
  padding-left: 1.09375vw;
  padding-top: 0.2604166666666667vw;
}

.an_selectat .left {
  padding-right: 0.9895833333333334vw;
  padding-top: 0.2604166666666667vw;
}

.an_selectat .right {
  padding-left: 1.09375vw;
  padding-top: 0.2604166666666667vw;
}

.campuri {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.center {
  text-align: center;
  clear: both;
}
table {
  clear: both;
  border-spacing: 0.4166666666666667vw;
}
table .week td {
  color: #ffffff;
  font-family: "Trocchi";
  font-size: 1.25vw;
  font-weight: 100;
}
table td {
  width: 2vw;
  height: 2vw;
  vertical-align: middle;
  text-align: center;
}
tbody td {
  cursor: pointer;
  border-radius: 100%;
}
tbody td.selected {
  color: #ffffff;
}
tbody td.selected:hover {
  color: #ffffff;
}
tbody td:hover {
  color: #ffffff;
}
.calendar {
  background-color: #111111;
  position: absolute;
  top: 1.65vw;
  border: 0.2604166666666667vw solid #bfbc8e;
  margin-left: -2.8645833333333335vw;
  padding-top: 1.4vw;
  padding-left: 1.5vw;
  padding-right: 1.9vw;
  z-index: 99;
}

[v-cloak] {
  display: none;
}

/**/
.rezerva_masa h2 {
  font-family: "Trocchi";
  font-size: 3.6458333333333335vw;
  color: #bfbc8e;
  font-weight: 100;
  margin-bottom: 2.147916666666667vw;
  margin-top: 0.50625vw;
  text-align: center;
}

.campuri label {
  font-family: "Trocchi";
  font-size: 1.25vw;
  color: #bfbc8e;
  font-weight: 100;
  padding-right: 0.9375vw;
}

.formular_rezervare {
  width: 65%;
  float: left;
}

.content_formular {
  width: 52.041667vw;
  margin: 0 auto;
}

.content_formular input {
  font-family: "Trocchi";
  font-size: 1.25vw;
  color: #ffffff;
  font-weight: 100;
  background: transparent;
  border-top: 0vw;
  border-left: 0vw;
  border-right: 0vw;
  outline: none;
  border-bottom: 0.2604166666666667vw solid #bfbc8e;
  line-height: 2.03125vw;
}

.content_formular input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #ffffff;
}
.content_formular input::-moz-placeholder {
  /* Firefox 19+ */
  color: #ffffff;
}
.content_formular input:-ms-input-placeholder {
  /* IE 10+ */
  color: #ffffff;
}
.content_formular input:-moz-placeholder {
  /* Firefox 18- */
  color: #ffffff;
}

.rezerv_check_square {
  height: 16px !important;
  width: 16px !important;
  padding-bottom: 0 !important;
}
.custom-select {
  font-family: "Trocchi";
  font-size: 1.25vw;
  color: #ffffff;
  font-weight: 100;
  background: transparent;
  border-top: 0vw;
  border-left: 0vw;
  border-right: 0vw;
  outline: none;
  border-bottom: 0.2604166666666667vw solid #bfbc8e;
  display: inline-block;
  width: 10.416666666666668vw;
}

/**/
.custom-select {
  position: relative;
  padding-bottom: 0.1vw !important;
}
.custom-select select {
  display: none; /*hide original SELECT element:*/
}
.select-selected {
  background-color: transparent;
}

.select-selected {
  padding-bottom: 0vw !important;
}

/*style the items (options), including the selected item:*/
.select-items div,
.select-selected {
  color: #ffffff;
  cursor: pointer;
  user-select: none;
  padding-left: 0.8333333333333334vw;
}
/*style items (options):*/
.select-items {
  position: absolute;
  background-color: #111111;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  border: 6px solid #bfbc8e;
  width: 12vw;
  margin-left: -0.9375vw;
  max-height: 19.114583vw;
  overflow: scroll;
  overflow-x: hidden;
}
/*hide the items when the select box is closed:*/
.select-hide {
  display: none;
}
.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
  color: #ffffff;
}
.select-items div:first-child {
  padding-top: 1.04vw;
}



.select-items div {
  text-align: center;
}

.select-items div {
  padding-left: 1.5625vw;
  padding-bottom: 0.625vw;
  text-align: left;
  color: #bfbc8e;
}

.data p {
  font-family: "Trocchi";
  font-size: 1.25vw;
  color: #bfbc8e;
  font-weight: 100;
  padding-right: 0.9375vw;
  float: left;
}

div#date-picker input {
  width: 15.260416666666668vw;
}

#map_canvas {
  /* height: 92.5%; */
  height: 100%;
  width: 50%;
  position: relative;
  overflow: hidden;
  filter: grayscale(100%);
}

div#date-picker input {
  padding-left: 1.09375vw;
  line-height: 1.6145833333333335vw;
}

.an_selectat {
  position: absolute;
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
  right: 2.09375vw;
}

.luna_selectat {
  position: absolute;
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
  left: 1.9791666666666667vw;
}

.an_selectat .year {
  font-family: "Trocchi";
  color: #ffffff;
  font-size: 1.25vw;
}

.err_field {
  border-bottom: 6px solid red !important;
}

.luna_selectat .month {
  font-family: "Trocchi";
  color: #ffffff;
  font-size: 1.25vw;
}

td.day {
  font-family: "Trocchi";
  color: #bfbc8e;
  font-size: 1.25vw;
  font-weight: 100;
}

.line1 {
  height: 0vw;
  width: 97%;
  border: 0;
  border-bottom: 0.2604166666666667vw;
  border-style: solid;
  border-color: #bfbc8e;
  padding-top: 0vw;
  margin: 0 auto;
  margin-top: -1.1979166666666667vw;
  margin-bottom: 1.1458333333333335vw;
}

div#date-picker {
  cursor: pointer;
  float: left;
  position: relative;
}

.ora-stay, .ora {
  display: inline-block;
  padding-left: 1.0416666666666667vw;
}

.ora-stay .select-items , .ora .select-items {
  overflow-y: scroll;
  height: 19.052083333333334vw;
  width: 8.066667vw;
}

.select-items::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  background-color: #111111;
}

.select-items::-webkit-scrollbar {
  width: 5px;
}

.select-items::-webkit-scrollbar-thumb {
  /* background-color: #bfbc8e; */
  border: 2.258333vw solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-right: 0.8333333333333334vw solid rgba(0, 0, 0, 0);
  border-top: 1.40625vw solid rgba(0, 0, 0, 0);
}

.confirm p {
  font-family: "Trocchi";
  color: #bfbc8e;
  font-size: 1.25vw;
  font-weight: 100;
}

.data {
  padding-bottom: 0vw !important;
}



.phone_and_mail {
  margin-top: -0.6729166666666667vw;
  margin-left: -0.295vw;
}

input#client_telefon {
  width: 11.5125vw;
  padding-left: 0.58125vw;
}

input#client_email {
  width: 14.725vw;
  padding-left: 0.78125vw;
}

.message h3 {
  font-family: "Trocchi";
  color: #bfbc8e;
  font-size: 1.25vw;
  font-weight: 100;
  margin-bottom: 0.8333333333333334vw;
}

.message p {
  color: #ffffff;
  font-family: "Trocchi";
  font-size: 1.1979166666666667vw;
  font-weight: 100;
  margin-top: 0.5208333333333334vw;
  margin-bottom: 0.5208333333333334vw;
}

textarea.message_area {
  background-color: transparent;
  border-bottom: 0.2604166666666667vw solid #bfbc8e;
  outline: none;
  border-top: 0vw;
  border-left: 0vw;
  border-right: 0vw;
  font-family: "Trocchi";
  font-size: 1.25vw;
  color: #ffffff;
  font-weight: 100;
}
textarea.message_area::placeholder {
  background-color: transparent;
  text-align: center;
  font-family: "Trocchi";
  color: #ffffff;
  font-weight: 100;
}

.g-recaptcha {
  float: left;
  margin-top: 0.7291666666666667vw;
  margin-left: 2.99375vw;
}

.send {
  margin-top: 1.1979166666666667vw;
  padding-top: 0.36458333333333337vw;
}

.send .button_vezi_meniu a {
  /* margin-left: -1.7708333333333335vw; */
  padding-right: 2.447916666666667vw;
}

.imagine_rezervare img {
  margin-top: 0.86875vw;
  width: 26.5vw;
  margin-left: 3.204166666666667vw;
}

.ora-stay .custom-select , .ora .custom-select {
  width: 6.56667vw;
}

.phone_and_mail label {
  padding-left: 0.75vw;
}

.phone_and_mail label:first-child {
  padding-left: 0vw;
}

.powerd_by {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  background: url(./assets/img/background.png);
  background-repeat: no-repeat;
  padding-left: 1.5625vw;
  padding-right: 1.5625vw;
}

#section4 {
  background: url(./assets/img/background.png);
  background-repeat: repeat-y;
}

.text_contact h3 {
  font-family: "Trocchi";
  font-size: 3.1458333333333335vw;
  font-weight: 100;
  color: #bfbc8e;
  margin-top: 0.78125vw;
  margin-bottom: 0.78125vw;
}

.text_contact p {
  font-family: "Trocchi";
  font-size: 1.7708333333333335vw;
  font-weight: 100;
  color: #bfbc8e;
  margin-top: 1.1625vw;
  margin-bottom: 1.1625vw;
}

.text_contact p span {
  font-size: 1.7708333333333335vw;
  color: #ffffff;
  font-weight: normal;
}

.text_contact a {
  font-family: "Trocchi";
  font-size: 1.7708333333333335vw;
  font-weight: 100;
  color: #bfbc8e;
  text-decoration: none;
  display: block;
  margin-top: 1.1625vw;
  margin-bottom: 1.1625vw;
  outline: none;
}

.text_contact a span {
  font-size: 1.7708333333333335vw;
  color: #ffffff;
  font-weight: normal;
}
.program_text {
  margin-top: 2vw;
}
.program_text h3 {
  font-family: "Trocchi";
  font-size: 3.1458333333333335vw;
  font-weight: 100;
  color: #bfbc8e;
  margin-top: 0.5625vw;
  margin-bottom: 1.5625vw;
}

.program_text p {
  font-family: "Trocchi";
  font-size: 1.7708333333333335vw;
  font-weight: 100;
  color: #bfbc8e;
  margin-top: 1.5625vw;
  margin-bottom: 1.5625vw;
}

.program_text p span {
  font-size: 1.7708333333333335vw;
  color: #ffffff;
  font-weight: normal;
}

.contact_info {
  width: 50%;
  float: left;
}

.clear {
  clear: both;
}

.info_1 {
  padding-left: 7.1vw;
  padding-top: 0vw;
}

.pin_img {
  padding-top: 3.36vw;
}

.pin_img svg:not(.contact_info_svg) {
  width: 40px;
  height: 40px;
  max-width: 40px;
  max-height: 40px;
}

.cont_s4 {
  height: 100vh;
  width: 100%;
}

.program_img {
  padding-top: 3vw;
}

.footer_links {
  position: absolute;
  bottom: 0;
  height: 7.5%;
  width: 100%;
  background-color: #ffffff;
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
}

.footer_links a {
  font-family: "Work Sans Bold";
  text-decoration: none;
  font-size: 0.9375vw;
  color: #939393;
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
  float: left;
  align-items: center;
  padding-left: 3.0208333333333335vw;
  outline: none;
}

.footer_links a:first-child {
  padding-left: 6.9vw;
}



@keyframes example {
  0% {
    border-radius: 2.0833333333333335vw;
    /* background-color: rgba(0, 0, 0, 0.7); */
  }
  25% {
    border-radius: 1.0833333333333335vw;
    /* background-color: rgba(0, 0, 0, 0.7); */
  }
  30% {
    border-radius: 0;
    /*  background-color: rgba(0, 0, 0, 0.7); */
  }
  95% {
    border-radius: 0;
    /*  background-color: rgba(0, 0, 0, 0.7); */
  }
  100% {
    border-radius: 0;
    /* background-color: #000; */
  }
}

@keyframes black_tn_border_anim {
  0% {
    border-radius: 2.0833333333333335vw;
    /* color: black; */
    /* background-color: rgba(0, 0, 0, 0.7); */
  }
  25% {
    border-radius: 1.0833333333333335vw;
    /* color: black; */

    /* background-color: rgba(0, 0, 0, 0.7); */
  }
  30% {
    border-radius: 0;
    /* color: black; */
    /*  background-color: rgba(0, 0, 0, 0.7); */
  }
  95% {
    border-radius: 0;
    /* color: black; */

    /*  background-color: rgba(0, 0, 0, 0.7); */
  }
  100% {
    border-radius: 0;
    /* color: black; */
    /* background-color: #000; */
  }
}
.rezerva a:hover,
.fill_anim:hover,
.button_vezi_meniu a:hover {
  animation-name: example;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.rezerva a,
.command_menu_list_item_button,
.fill_anim,
.fill_anim_black,
.button_vezi_meniu {
  user-select: none;
  -moz-user-select: none;
}

.fill_anim_black:hover {
  animation-name: black_tn_border_anim;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  color: black;
}

.field_error {
  border-color: #ff3333 !important;
  background-image: url(./assets/img/obligatoriu.png) !important;
  background-repeat: no-repeat !important;
  background-position-x: 100% !important;
  background-position-y: 0% !important;
  padding-right: 1.1458333333333335vw;
}

.ecran_succes {
  display: none;
}

.message {
  padding-bottom: 0vw !important;
}

.content_succes h3 {
  font-family: "Trocchi";
  font-size: 3.6458333333333335vw;
  font-weight: 100;
  color: #bfbc8e;
  text-align: center;
  margin-bottom: 0.36458333333333337vw;
}

.content_succes p {
  font-family: "Trocchi";
  font-size: 1.7708333333333335vw;
  font-weight: 100;
  color: #bfbc8e;
  margin-top: 0.2604166666666667vw;
}

.rezervare_noua {
  text-decoration: none;
  outline: none;
  font-family: "Trocchi";
  font-size: 2.291666666666667vw;
  font-weight: 100;
  color: #ffffff;
  background-image: url("./assets/img/rezervareNoua.png");
  background-repeat: no-repeat;
  background-position-x: 99%;
  background-position-y: 50%;
  padding-right: 3.28125vw;
}

.ecran_succes {
  height: 84vh;
}

.content_succes {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 15px;
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.new_rez {
  padding-top: 5.28125vw;
}

.react-datepicker__day--disabled {
  opacity: 0.35;
}
.datepicker_reservations_calendar {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}
.datepicker_reservations_calendar .react-datepicker__navigation--next {
  right: 30px !important;
  top: 40px !important;
}

.datepicker_reservations_popper[data-placement^="bottom"] {
  margin-top: -3.02vw !important;
  width: 100%!important;
  top: 100%!important;
}

.datepicker_reservations_calendar .react-datepicker__navigation--previous {
  left: 30px !important;
  border-right-color: #ccc;
  top: 40px !important;
}

.datepicker_reservations_calendar .react-datepicker {
  position: absolute !important;
  width: 17.552083vw;
  /*border: 6px solid #bfbc8e !important;*/
  padding-left: 2.03125vw !important;
  padding-right: 2.083vw !important;
  z-index: 99 !important;
  padding-top: 20px;
}

.ecran_formular_initial {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  height: 100%;
}

/*reservation datepicker edits*/
.data_here {
  cursor: pointer;
  float: left;
  position: relative;
}
.datepicker_reservations_calendar .react-datepicker-wrapper input {
  padding-left: 1.09375vw;
  line-height: 1.6145833333333335vw;
  width: 15.260416666666668vw;
  position: relative;
  cursor: pointer;
}
.data_here img {
  position: absolute;
  z-index: 0;
  right: 1.04167vw;
  width: 0.8854167vw;
  top: 0.3125vw;
  height: 0.9375vw;
}
.datepicker_reservations_calendar .ui-widget-content {
  border-radius: 0 !important;
}
.datepicker_reservations_calendar .ui-widget-header {
  border: none !important;
  background: transparent !important;
}
.datepicker_reservations_calendar a.ui-state-default.ui-state-active {
  background: none;
  border: none;
  color: #fff !important;
}
.datepicker_reservations_calendar .react-datepicker__triangle {
  display: none;
}
.datepicker_reservations_calendar .react-datepicker__week {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.datepicker_reservations_calendar .react-datepicker__day-names {
  display: flex;
  justify-content: space-between;
  margin: 25px 0px;
}
.datepicker_reservations_calendar .react-datepicker__day {
  font-family: "Trocchi" !important;
  font-size: 1.25vw;
  background: transparent !important;
  border: none !important;
}
.datepicker_reservations_calendar .ui-datepicker-prev .ui-icon {
  background-image: url(./assets/img/left_ar.png) !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  cursor: pointer;
}
.datepicker_reservations_calendar .ui-datepicker-prev-hover {
  left: 2px !important;
  top: 2px !important;
}
.datepicker_reservations_calendar .ui-datepicker-next .ui-icon {
  background-image: url(./assets/img/right_ar.png) !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  cursor: pointer;
}
.datepicker_reservations_calendar .ui-datepicker-next-hover {
  right: 2px !important;
  top: 2px !important;
}
.datepicker_reservations_calendar .ui-state-hover,
.datepicker_reservations_calendar .ui-widget-content .ui-state-hover,
.datepicker_reservations_calendar .ui-widget-header .ui-state-hover,
.datepicker_reservations_calendar .ui-state-focus,
.datepicker_reservations_calendar .ui-widget-content .ui-state-focus,
.datepicker_reservations_calendar .ui-widget-header .ui-state-focus,
.datepicker_reservations_calendar .ui-button:hover,
.datepicker_reservations_calendar .ui-button:focus {
  background: transparent !important;
  border: none !important;
}
.datepicker_reservations_calendar .react-datepicker__month-container {
  width: 100%;
  float: none;
}
.datepicker_reservations_calendar .react-datepicker__current-month {
  font-family: "Trocchi" !important;
  font-size: 1.25vw !important;
  display: inline-block;
  font-weight: normal !important;
  margin-right: 0.8854vw;
}
.datepicker_reservations_calendar .react-datepicker__day-name {
  font-family: "Trocchi" !important;
  font-size: 1.25vw;
  font-weight: 400 !important;
}
.datepicker_reservations_calendar .ui-datepicker .ui-datepicker-title {
  text-align: center !important;
  font-family: "Trocchi" !important;
  margin: 0 1.667vw !important;
}
.datepicker_reservations_calendar .ui-datepicker-calendar {
  position: relative;
  padding-top: 2.604167vw !important;
}
.datepicker_reservations_calendar .ui-datepicker-calendar:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0.20833vw;
}
.datepicker_reservations_calendar #ui-datepicker-div {
  position: absolute !important;
  /*top: 21.4583vw !important;*/
  left: 13.90625vw !important;
  width: 17.552083vw;
  border: 0.2604166666666667vw solid #bfbc8e !important;
  padding-left: 2.03125vw !important;
  padding-right: 2.083vw !important;
  z-index: 99 !important;
}
.datepicker_reservations_calendar span.ui-datepicker-year {
  display: inline-block;
  font-family: "Trocchi" !important;
  font-size: 1.25vw;
  color: #fff !important;
  font-weight: normal !important;
}
.datepicker_reservations_calendar .ui-datepicker table {
  margin: 0 !important;
  width: 100% !important;
  padding: 0.989583vw 1.04vw 0px !important;
  margin-bottom: 39px !important;
}
.datepicker_reservations_calendar .ui-datepicker td span,
.datepicker_reservations_calendar .ui-datepicker td a {
  text-align: center !important;
  padding: 0px !important;
}
.datepicker_reservations_calendar .ui-datepicker .ui-datepicker-header {
  border-radius: 0 !important;
  padding: 1.09375vw 0px 1.14583vw !important;
}
.datepicker_reservations_calendar .ui-datepicker .ui-datepicker-prev,
.datepicker_reservations_calendar .ui-datepicker .ui-datepicker-next {
  width: 0.52083vw !important;
  height: 0.9375vw !important;
  border-radius: 0;
  background: transparent !important;
  border: none !important;
  top: 1.5104167vw !important;
}
.datepicker_reservations_calendar .ui-datepicker .ui-datepicker-prev span,
.datepicker_reservations_calendar .ui-datepicker .ui-datepicker-next span {
  margin: 0 !important;
  width: 100%;
  height: auto;
  background-size: contain;
  top: 0 !important;
  left: 0 !important;
}
.datepicker_reservations_calendar .ui-datepicker th {
  text-align: right !important;
}
.datepicker_reservations_calendar .ui-datepicker-calendar tbody {
  padding-top: 0.6770833vw !important;
}
.datepicker_reservations_calendar .ui-datepicker-calendar thead tr th {
  text-align: center !important;
  padding: 1.979167vw 0px 1.25vw !important;
}
.datepicker_reservations_calendar table.ui-datepicker-calendar td {
  padding: 0.052083vw 0.052083vw 0.208333vw !important;
  width: auto;
  height: auto;
}
.datepicker_reservations_calendar .disabled {
  cursor: default;
  pointer-events: none;
  opacity: 0.35;
}
.datepicker_reservations_calendar .hours_selector_cm .select-selected {
  position: relative !important;
  z-index: 11 !important;
  cursor: pointer;
}
.datepicker_reservations_calendar .hours_selector_cm .select-selected:before {
  content: "";
  z-index: 0;
  position: absolute;
  width: 0.9375vw;
  height: 0.9375vw;
  background-image: url("./assets/img/ceas-program.png");
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  right: 1.04167vw;
  top: 0.3125vw;
}
/*reservation datepicker edits*/

/*Responsive*/
@media only screen and (max-width: 1490px) {
  .meniu_principal a {
    font-size: 1.4458333333333335vw;
  }
  .rezerva_section2 a {
    background-size: 1.75vw;
  }
  .your-class div {
    background-position: center bottom;
    background-size: 81vh;
  }
}

@media only screen and (max-width: 1360px) {
  .rest_name {
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox; /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
    align-items: center;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 1120px) {
  .description h1 {
    font-size: 4.0458333333333335vw;
  }
  .description {
    width: 42.020833333333336vw;
    margin-bottom: 3.125vw;
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox; /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
  }
  .meniu_principal a {
    font-size: 1.7458333333333335vw;
  }
  .rezerva a {
    font-size: 1.65vw;
  }
  .button_vezi_meniu a {
    font-size: 1.65vw;
  }
  .content_section3 {
    min-height: 86vh;
  }
}

@media only screen and (max-width: 1160px) {
  .rest_name {
    margin-top: 20%;
  }
  .citat_text p {
    line-height: 2.9520833333333335vw;
  }
  .ghilimele img {
    margin-top: 0.5208333333333334vw;
    margin-bottom: 0.5208333333333334vw;
  }
}

@media only screen and (max-width: 1050px) {
  .content_formular {
    width: 55.041667vw;
  }
  .program_img img {
    width: 7%;
  }
  .pin_img img {
    width: 7%;
  }
}

@media (max-width: 1024px) {
  .datepicker_reservations_calendar .ui-datepicker .ui-datepicker-prev,
  .datepicker_reservations_calendar .ui-datepicker .ui-datepicker-next {
    width: 20px !important;
    height: auto !important;
    border-radius: 0;
    background: transparent !important;
    border: none !important;
    top: 8px !important;
  }
  .datepicker_reservations_calendar .ui-datepicker-calendar thead tr th span {
    font-size: 15px;
  }

  .datepicker_reservations_calendar #ui-datepicker-div {
    left: 0 !important;
    width: 80%;
    margin-left: 10%;
    padding-left: 0;
    padding-right: 0;
    z-index: 99 !important;
  }

  .datepicker_reservations_calendar span.ui-datepicker-month {
    font-size: 15px !important;
  }

  .datepicker_reservations_calendar span.ui-datepicker-year {
    font-size: 15px;
  }

}

@media only screen and (max-width: 950px) {
  .formular_rezervare {
    width: 70%;
  }
  .content_formular {
    width: 96%;
  }
  .rest_name {
    margin-top: 40%;
  }
  .content_section2 {
    padding-right: 13%;
  }

  .social_section > a > .svg_img_social_media {
    width: 35px;
    height: 35px;
  }
  .info_1 > :last-child {
    margin-bottom: 60px;
  }
  .social_section {
    padding-top: 10px;
  }
}

@media only screen and (max-width: 880px) {
  .meniu_principal a {
    font-size: 2.0458333333333335vw;
  }
  .description {
    width: 46.020833333333336vw;
  }
  .rezerva a {
    font-size: 1.85vw;
  }
}

@media only screen and (max-width: 854px) {
  .content_formular {
    display: inline-block;
    width: 100%;
    padding: 0px 36px;
    text-align: center;
  }
  .formular_rezervare {
    width: 100%;
  }
  .g-recaptcha {
    transform: scale(0.6) !important;
    -webkit-transform: scale(0.6) !important;
  }
  .button_vezi_meniu {
    margin-left: -1.8229166666666667vw;
  }
}

/* Editari Iulian */
body {
  margin: 0;
  /* background-color: #3f1e69; */
}
#fullpage > .section {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100vh;
}
@media screen and (min-width: 1025px) and (min-height: 770px) { 
  #section1 {
    height: 100vh!important;
  }
}
@media screen and (min-width: 769px) and (max-height: 770px) { 
  .description {
    padding-top: 1.6666666666666667vw;
    margin-top: 14%;
    margin-bottom: 6%;
  }
  .meniu_principal {
    margin-top: 0;
  }
}
@media screen and (max-height: 770px) { 
  #section1 {
    height: 770px!important;
  }

  .description {
    padding-top: 100px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .description h1 {
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 3vw;
  }
}
@media screen and (max-height: 330px) { 
  .first-screen-last-section-cont {
    justify-content: center;
  }
  .description {
    padding-top: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
}
#section1,
#section2,
#section3,
#section4 {
  position: relative;
}

/* Cosmin edits - START */
.mobi {
  display: none;
}
.desk {
  display: block;
}
/* Cosmin edits - END */

@media screen and (max-width: 768px) {
  #fullpage > .section {
    display: block;
    table-layout: unset;
    width: 100%;
    height: 100%;
    clear: both;
  }

  .hours_selector_cm .select-selected {
    font-size: 16px !important;
  }



  .select-items {
    max-height: 300px;
  }

  input#client_email {
    width: 100%;
    padding-left: 0;
  }

  .campuri > div:not(.rezerv_check_square) {
    width: 100% !important;
    font-size: 16px!important;
    margin-left: 0 !important;
  }
  .rezerv_check_square {
    height: 16px !important;
    width: 16px !important;
    padding-bottom: 0 !important;
  }
  input#client_telefon {
    width: 100%;
    padding-left: 0;
  }

  #section1 .info_location {
    position: inherit;
    top: 0;
    padding: 0 34px;
    text-align: center;
  }

  .logo_restaurant_cm {
    max-width: 94px;
    width: 100% !important;
  }
  div#section1 {
    background-repeat: no-repeat;
    background-position: center;
    padding: 30px 0 55px 0;
    background-size: cover;
  }
  .info_location > a {
    margin-top: 0;
  }
  .meniu_principal a {
    color: #fff;
    font-size: 16px;
    padding: 13px 10px !important;
    display: inline-block;
  }

  #section1 .description {
    width: 100%;
    margin-bottom: 0;
    justify-content: center;
  }

  .ghilimele {
    width: 100%;
  }

  #section1 .description h1 {
    font-size: 39px;
    color: #fff;
    margin-top: 5px;
    line-height: 49px;
    margin-bottom: 0;
  }

  #section1 .rezerva a {
    display: inline-block;
    font-size: 16px;
    color: #fff;
    background: rgba(0, 0, 0, 0.5);
    padding: 15px 39px;
    margin-top: 41px;
    border-radius: 30px;
    border: 4px solid #bfbc8e;
    line-height: inherit;
  }

  .linie_meniu {
    /* display: none; */
    border-width: 4px !important;
    margin-bottom: 0px;
    width: 247px;
  }

  .meniu_principal {
    display: inline-block;
    margin: 0;
    /* border-top: 4px solid #bfbc8e; */
    /* margin-top: 5px !important; */
  }

  #section2 {
    display: flex !important;
    flex-flow: column;
  }

  .slider_section2 {
    order: 2;
    width: 100%;
    height: 360px;
    float: none;
  }

  .react-datepicker {
    left: 0 !important;
  }

  .datepicker_reservations_popper[data-placement^="bottom"] {

    top: 189% !important;
  }


  .ora-stay .select-items , .ora .select-items {
    max-height: 284px;
    margin-left: 0;
  }

  .select-items div {
    padding: 7px 20px !important;
    text-align: center;
  }

  button.slick-next.slick-arrow,
  button.slick-prev.slick-arrow {
    top: 200px;
  }

  .slick-slide img {
    height: auto;
  }

  .descriere_section2 {
    order: 1;
    width: 100%;
  }

  #section2 .rest_name {
    margin-top: 0;
  }

  #section2 .titlu_restaurant h1 {
    font-size: 39px;
    color: #bfbc8e;
    line-height: inherit;
    margin-top: 0;
  }

  #section2 .ghilimele img {
    margin-top: 14px;
    margin-bottom: 5px;
    width: 22px !important;
  }

  #section2 .citat_text p {
    font-size: 19px;
    color: #bfbc8e;
    line-height: 28px;
    padding-right: 35px;
  }
  #section2 .your-class div {
    background-position: center;
    background-size: cover;
  }
  button.slick-next.slick-arrow,
  button.slick-prev.slick-arrow {
    background-size: 23px;
  }
  #section2 .your-class.slick-slider {
    height: 100% !important;
  }
  #section2 .your-class div {
    height: 100%;
  }
  /* Cosmin edits - START */
  * {
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box; /* Opera/IE 8+ */
  }
  .mobi {
    display: block;
  }
  .desk {
    /* display: none; */
    margin: 0 auto;
    font-size: unset;
  }

  .desk .svg_item {
    font-size: 18px;
    margin-left: -3%;
  }

  .svg_item > div {
    transform: scale(0.8);
  }

  .button_vezi_meniu a {
    margin-top: 20px;
    margin-bottom: 15px;
  }

  .phone_and_mail label:first-child {
    margin-top: 0px;
  }

  .content_section2 {
    padding: 63px 34px 44px 34px;
  }
  .imagine_rezervare {
    display: none;
  }
  .content_section3 {
    height: auto;
    min-height: auto;
    display: inline-block;
    width: 100%;
    clear: both;
    border: 4px solid #bfbc8e;
    margin: 0px;
    padding: 0px;
  }
  .powerd_by {
    width: 150px;
    bottom: 5px;
    padding-left: 20px;
    padding-right: 20px;
    left: 0;
    margin: 0 auto;
    right: 0;
    transform: none;
  }
  .powerd_by img {
    width: 100%;
    height: auto;
  }
  #section3 {
    padding: 20px 20px;
  }
  .campuri > div {
    padding-bottom: 0px;
  }
  .nume_client,
  .rezervare_persoane,
  .data {
    padding-left: 0px;
  }
  .campuri label {
    font-size: 16px !important;
    font-weight: 400;
    padding-right: 0px;
    width: 100%;
    text-align: center;
    clear: both;
    display: block;
  }

  .nume_client input {
    padding-left: 0px;
  }
  .content_formular input {
    font-size: 16px;
    border-bottom: 4px solid #bfbc8e;
    line-height: normal;
    width: 100%;
    text-align: center;
    padding-bottom: 4px;
  }
  .select-selected {
    padding-left: 0px;
    padding-bottom: 4px !important;
  }
  .confirm {
    padding-left: 0px;
    padding-bottom: 0vw !important;
  }
  .data_here input {
    padding-left: 0px;
  }
  .phone_and_mail {
    margin-top: 0px;
    margin-left: 0px;
  }
  .send {
    margin-top: 0px;
    padding-top: 0px;
  }
  .button_vezi_meniu {
    padding-top: 0px;
    padding-left: 0px;
    margin-left: 0px;
    text-align: center;
    width: 100%;
  }
  .send .button_vezi_meniu a {
    margin-left: 0px;
    padding-right: 0px;
  }
  .button_vezi_meniu a {
    font-size: 16px;
    border-radius: 50px;
    /* border: 4px solid #bfbc8e; */

    padding: 15px 39px !important;
    display: inline-block;
    margin-bottom: 60px;
    margin-top: 36px;
  }
  .rezerva_masa h2 {
    font-size: 39px;
    line-height: 44px;
    margin-top: 42px;
    margin-bottom: 38px;
    padding-left: 0px;
  }
  .data p {
    float: none;
    font-size: 16px;
    padding-right: 0;
  }
  .data_here {
    float: none;
    width: 100%;
  }
  .data_here img {
    width: 14px;
    height: auto;
    right: 15px;
    top: 0px;
  }
  .ora-stay, .ora {
    padding-left: 0px;
    width: 100%;
  }
  .ora-stay .custom-select , .ora .custom-select {
    width: 100%;
  }
  .custom-select {
    font-size: 16px;
    border-bottom: 4px solid #bfbc8e;
    width: 100%;
  }
  .confirm p {
    font-size: 16px;
  }
  #map_canvas {
    height: 300px !important;
    width: 100% !important;
    display: block;
  }
  input#phone {
    width: 100%;
    padding-left: 0px;
  }
  input#emai {
    width: 100%;
    padding-left: 0px;
  }
  .message h3 {
    font-size: 16px;
    padding-left: 0px;
    margin-bottom: 12px;
  }
  .message p {
    font-size: 16px;
    margin-top: 0px;
    margin-bottom: 10px;
    padding-left: 0px;
  }
  textarea.message_area {
    margin-left: 0px;
    border-bottom: 4px solid #bfbc8e;
    font-size: 16px;
    width: 100%;
  }
  .hours_selector_cm .select-selected:before {
    content: "";
    width: 15px;
    height: 15px;
    right: 15px;
    top: 0px;
  }
  .contact_info {
    float: none;
    width: 100%;
    clear: both;
  }
  .info_1 h3 {
    font-size: 39px;
    margin-top: 0px;
    margin-bottom: 25px;
  }
  .info_1 {
    padding: 0px 40px;
  }
  .pin_img {
    padding-top: 67px;
  }
  .pin_img img {
    width: 21px;
  }
  .text_contact p {
    font-size: 19px;
    margin-top: 0px;
    margin-bottom: 18px;
  }
  .text_contact p span,
  .text_contact a span {
    font-size: 19px;
    font-weight: 700;
  }
  .text_contact a {
    font-size: 19px;
    margin-top: 18px;
    margin-bottom: 0px;
  }
  .program_img {
    padding-top: 56px;
  }
  .program_img img {
    width: 29px;
  }



  .semibold_text_formular_ok {
    font-weight: 500;
  }

  .disclaimer {
    font-size: 12px !important;
  }
  /* Cosmin edits - END */

  /* Andrei edits - START */
  .social_section {
    padding-top: 27px;
  }

  .social_section > a > img {
    width: 35px;
  }

  .social_section > a {
    margin-right: 30px;
  }
  /* Andrei edits - END */
}

/* #Editari Iulian */

/* Editari Andrei */

.program_white_text {
  color: #fff;
}

.check_agree {
  margin-top: 1.052vw;
  padding-bottom: 0px !important;
}

.check_promo_venue,
.check_promo_poloniq {
  padding-bottom: 0px !important;
  margin-bottom: 10px !important;
  display: flex;
  align-items: center;
}

.check_promo_venue span,
.check_promo_poloniq span {
  font-size: 0.729vw;
  font-family: "Trocchi";
}

.container_venue,
.container_poloniq {
  font-size: 0.9vw !important;
}

.container_venue,
.container_poloniq {
  display: flex;
  justify-content: flex-start;
  position: relative;
  padding-left: 1.302vw;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container_venue input,
.container_poloniq input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 12px;
  width: 12px;
  /* background-color: #eee; */
  border: 2px solid white;
}

/* On mouse-over, add a grey background color */
.container_venue:hover input ~ .checkmark,
.container_poloniq:hover input ~ .checkmark {
  /* background-color: #ccc; */
}

/* When the checkbox is checked, add a blue background */
.container_venue input:checked ~ .checkmark,
.container_poloniq input:checked ~ .checkmark {
  /* background-color: #2196F3; */
  border: 2px solid white;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
  border: solid white 1px;
}

/* Show the checkmark when checked */
.container_venue input:checked ~ .checkmark:after,
.container_poloniq input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container_venue .checkmark:after,
.container_poloniq .checkmark:after {
  left: 3px;
  top: 3px;
  width: 4px;
  height: 4px;
  /* border: solid white;
  border-width: 1px 1px 1px 1px; */
  background-color: white;
  /* -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); */
}

.disclaimer {
  font-family: "Trocchi";
  font-size: 11px;
  width: 100%;
  text-align: center;
  margin-top: 1.5vw;
}

.g-recaptcha {
  margin: 0 !important;
  padding: 0 !important;
}

.send {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

#rezervare_acum {
  cursor: pointer;
}

#section5 {
  height: 7.5% !important;
  display: flex;
}

.footer_links {
  position: unset;
  height: 45px;
}

.container_venue > div,
.container_poloniq > div {
  padding-bottom: 0px !important;
}
.check_text {
  font-size: 0.729vw !important;
}
@media screen and (max-width: 1366px) {
  .checkmark {
    top: 0px;
  }

  .container_venue,
  .container_poloniq {
    padding-left: 1.7vw;
  }

  .check_text {
    font-size: 0.929vw !important;
  }

  .disclaimer {
  }

  .rezerva_masa > h2 {
    margin-bottom: 1.147916666666667vw;
  }
}
@media screen and (max-width: 768px) {
  .check_promo_venue > span,
  .check_promo_poloniq > span {
    font-size: 12px !important;
    margin-left: 10px;
  }

  .check_promo_venue,
  .check_promo_poloniq {
    /* display: flex;
    justify-content: center; */
    margin-bottom: 0px !important;
  }

  .container_venue,
  .container_poloniq {
    text-align: left !important;
    padding-left: 30px;
    margin-top: 7px !important;
    margin-bottom: 12px !important;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
  }

  .disclaimer {
    margin-top: 0px;
    font-size: 12px !important;
    line-height: 18px;
  }

  .container_venue > div,
  .container_poloniq > div {
    margin-top: -3px;
    line-height: 18px;
  }

  .check_text {
    font-size: 13px !important;
    line-height: 10px;
    text-align: left;
  }

  .check_agree {
    margin: 0px !important;
  }

  .checkmark {
    top: 0px;
    width: 16px;
    height: 16px;
  }

  .container_venue .checkmark:after,
  .container_poloniq .checkmark:after {
    left: 3px;
    top: 3px;
    width: 4px;
    height: 4px;
  }

  .button_vezi_meniu a {
    margin-top: 24px;
    margin-bottom: 20px;
  }

  .react-datepicker {
    top: -32px;
  }

  .react-datepicker__current-month {
    font-size: 16px !important;
  }
}
/* End Editari Andrei */

/* ###### - Gabi edits - ####### */
/*helper classes */
._box_sizing_border_box_ {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.link_no_decoration {
  text-decoration: none;
  color: unset;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}
.link_no_class {
  color: unset;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}
.flex_all_ {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex_col_sp_between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
/* ista fb */

/* end insta fb */
/* program */
.contact_info_svg {
  width: 2.3vw;
  height: 2.3vw;
  margin-right: 8px;
}
/* program */
/* check rezervare */
.rezerv_check_cont {
  padding-bottom: 0px !important;
  margin-bottom: 10px !important;
}
.rezerv_check_label {
  font-family: "Trocchi";
  font-size: 0.729vw;
  font-weight: 100;
  padding-bottom: 0 !important;
}
.rezerv_check_square {
  height: 16px !important;
  width: 16px !important;
  padding-bottom: 0 !important;
  margin-right: 5px;
}
.rezerv_check_square > svg {
  width: 11 !important;
  height: 8px !important;
}

/* <dropdown_datepicker> */
.datepicker_dropdown_inner_container {
  max-height: unset!important;
  overflow: unset!important;
}
.dropdown_datepicker_calendar,
.dropdown_datepicker_popper {
  width: 100%!important;
}
.dropdown_datepicker_calendar {
  position: unset!important;
  width: 100%!important;
  box-sizing: border-box;
  padding: 0 10px!important;
  border: 4px solid #bfbc8e!important;
}
.dropdown_datepicker_popper {
  left: -5px!important;
  margin-top: 0!important;
  width: 100%!important;
  box-sizing: border-box;
  padding: 0;
  top: -30px!important;
}
.dropdown_datepicker_wrapper {
  display: none!important;
}
.Dropdown-option .react-datepicker__input-container{
  display: none!important;
}
.dropdown_datepicker_custom_header {
  width: 100%;
  height: 30px;
  text-align: center;
  position: relative;
}
.receipt_hour_dr_option {
  /* color: #bfbc8e; */
  cursor: pointer;
  user-select: none;
  justify-content: center!important;
}
/* .receipt_hour_dr_option:hover {
  color: grey;
} */

/*.... */
.dropdown_datepicker_calendar .ui-widget-content {
  border-radius: 0 !important;
}
.dropdown_datepicker_calendar .ui-widget-header {
  border: none !important;
  background: transparent !important;
}
.dropdown_datepicker_calendar a.ui-state-default.ui-state-active {
  background: none;
  border: none;
  color: #fff !important;
}
.dropdown_datepicker_calendar .react-datepicker__triangle {
  display: none;
}
.dropdown_datepicker_calendar .react-datepicker__week {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.dropdown_datepicker_calendar .react-datepicker__day-names {
  display: flex;
  justify-content: space-between;
  margin: 5px 0px;
}
.dropdown_datepicker_calendar .react-datepicker__day {
  font-family: "Trocchi" !important;
  font-size: 16px;
  background: transparent !important;
  border: none !important;
}
.dropdown_datepicker_calendar .ui-datepicker-prev .ui-icon {
  background-image: url(./assets/img/left_ar.png) !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  cursor: pointer;
}
.dropdown_datepicker_calendar .ui-datepicker-prev-hover {
  left: 2px !important;
  top: 2px !important;
}
.dropdown_datepicker_calendar .ui-datepicker-next .ui-icon {
  background-image: url(./assets/img/right_ar.png) !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  cursor: pointer;
}
.dropdown_datepicker_calendar .ui-datepicker-next-hover {
  right: 2px !important;
  top: 2px !important;
}
.dropdown_datepicker_calendar .ui-state-hover,
.dropdown_datepicker_calendar .ui-widget-content .ui-state-hover,
.dropdown_datepicker_calendar .ui-widget-header .ui-state-hover,
.dropdown_datepicker_calendar .ui-state-focus,
.dropdown_datepicker_calendar .ui-widget-content .ui-state-focus,
.dropdown_datepicker_calendar .ui-widget-header .ui-state-focus,
.dropdown_datepicker_calendar .ui-button:hover,
.dropdown_datepicker_calendar .ui-button:focus {
  background: transparent !important;
  border: none !important;
}
.dropdown_datepicker_calendar .react-datepicker__month-container {
  width: 100%;
  float: none;
}
.dropdown_datepicker_calendar .react-datepicker__current-month {
  font-family: "Trocchi" !important;
  font-size: 16px !important;
  display: inline-block;
  font-weight: normal !important;
}
.dropdown_datepicker_calendar .react-datepicker__day-name {
  font-family: "Trocchi" !important;
  font-size: 16px;
  font-weight: 400 !important;
  padding: 10px 0;
}
.dropdown_datepicker_calendar .ui-datepicker .ui-datepicker-title {
  text-align: center !important;
  font-family: "Trocchi" !important;
}
.dropdown_datepicker_calendar .ui-datepicker-calendar {
  position: relative;
  padding-top: 2.604167vw !important;
}
.dropdown_datepicker_calendar .ui-datepicker-calendar:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #bfbc8e;
}
.dropdown_datepicker_calendar #ui-datepicker-div {
  background-color: #111111 !important;
  position: absolute !important;
  /*top: 21.4583vw !important;*/
  left: 13.90625vw !important;
  width: 17.552083vw;
  border: 0.2604166666666667vw solid #bfbc8e !important;
  padding-left: 2.03125vw !important;
  padding-right: 2.083vw !important;
  z-index: 99 !important;
}
.dropdown_datepicker_calendar span.ui-datepicker-year {
  display: inline-block;
  font-family: "Trocchi" !important;
  font-size: 1.25vw;
  color: #fff !important;
  font-weight: normal !important;
}
.dropdown_datepicker_calendar .ui-datepicker table {
  margin: 0 !important;
  width: 100% !important;
  padding: 0.989583vw 1.04vw 0px !important;
  margin-bottom: 39px !important;
}
.dropdown_datepicker_calendar .ui-datepicker td span,
.dropdown_datepicker_calendar .ui-datepicker td a {
  text-align: center !important;
  padding: 0px !important;
}
.dropdown_datepicker_calendar .ui-datepicker .ui-datepicker-header {
  border-radius: 0 !important;
  padding: 1.09375vw 0px 1.14583vw !important;
}
.dropdown_datepicker_calendar .ui-datepicker .ui-datepicker-prev,
.dropdown_datepicker_calendar .ui-datepicker .ui-datepicker-next {
  width: 20px !important;
  height: auto !important;
  border-radius: 0;
  background: transparent !important;
  border: none !important;
  top: 8px !important;
}
.dropdown_datepicker_calendar .ui-datepicker .ui-datepicker-prev span,
.dropdown_datepicker_calendar .ui-datepicker .ui-datepicker-next span {
  margin: 0 !important;
  width: 100%;
  height: auto;
  background-size: contain;
  top: 0 !important;
  left: 0 !important;
}
.dropdown_datepicker_calendar .ui-datepicker th {
  text-align: right !important;
}
.dropdown_datepicker_calendar .ui-datepicker-calendar tbody {
  padding-top: 0.6770833vw !important;
}
.dropdown_datepicker_calendar .ui-datepicker-calendar thead tr th {
  text-align: center !important;
  padding: 1.979167vw 0px 1.25vw !important;
}
.dropdown_datepicker_calendar table.ui-datepicker-calendar td {
  padding: 0.052083vw 0.052083vw 0.208333vw !important;
  width: auto;
  height: auto;
}
.dropdown_datepicker_calendar .disabled {
  cursor: default;
  pointer-events: none;
  opacity: 0.35;
}
.dropdown_datepicker_calendar .hours_selector_cm .select-selected {
  position: relative !important;
  z-index: 11 !important;
  cursor: pointer;
}
.dropdown_datepicker_calendar .hours_selector_cm .select-selected:before {
  content: "";
  z-index: 0;
  position: absolute;
  width: 0.9375vw;
  height: 0.9375vw;
  background-image: url("./assets/img/ceas-program.png");
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  right: 1.04167vw;
  top: 0.3125vw;
}

.dropdown_datepicker_calendar .ui-datepicker-calendar thead tr th span {
  font-size: 15px;
}

.dropdown_datepicker_calendar #ui-datepicker-div {
  left: 0 !important;
  width: 80%;
  margin-left: 10%;
  padding-left: 0;
  padding-right: 0;
  z-index: 99 !important;
}

.dropdown_datepicker_calendar span.ui-datepicker-month {
  font-size: 15px !important;
}

.dropdown_datepicker_calendar span.ui-datepicker-year {
  font-size: 15px;
}

/* </Dropdown datepicker>

/* modal  .....*/
.append-element-container {
}
._payment_modal {
  width: 73vw;
  height: 40.36vw;
  min-width: 900px;
  max-width: 1400px;
  min-height: 600px;
  max-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  background-repeat: repeat;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.payment_conf_modal_container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 4px solid #bfbc8e;
  position: relative;
  min-width: 130px;
  min-height: 500px;
}
.paymentPopupStyle p,
.popup_paragraf {
  font-family: "Trocchi";
  text-align: center;
  font-size: 2.08vw;
  line-height: 4vw;
  color: white;
  margin: 0;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 20px;
}
.popup_paragraf_failed {
  line-height: 4vw;
}
.paymentPopupStyle h1,
.popup_paragraf_title {
  font-family: "Trocchi";
  font-size: 2.5vw;
  line-height: 5vw;
  margin-bottom: 0;
  margin-top: 0;
}
.pop_up_close_btn {
  position: absolute;
  width: 27px;
  height: 27px;
  top: 44px;
  right: 44px;
  cursor: pointer;
}
.popup_paragraf_mob {
  display: none;
}
.pop_up_close_btn > svg {
  width: 27px;
  height: 27px;
}
.btn_container____ {
  justify-content: center;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  margin-top: 23px;
  height: 90px;
}
.sub_btn____ {
  padding: 10px 97px;
  min-width: 102px;
  font-size: 24px;
  line-height: 55px;
  font-family: "Trocchi";
  color: black !important;
  white-space: nowrap;
  font-weight: normal;

  cursor: pointer;
  border: 4px solid #262626;
  text-decoration: none;
  border-radius: 50px;
  box-shadow: 0px 15px 25px 0px rgba(1, 1, 1, 0.15);
  outline: none;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* age confirmation modal */
.age_conf_modal_sub_container {
  width: 36.97vw;
  height: 19.27vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-family: "Trocchi";
}
.age_conf_modal_title {
  width: 100%;
  font-size: 3.125vw;
}
.age_conf_modal_content {
  width: 100%;
  font-size: 2.08vw;
}
.age_conf_modal_btns_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
/* end confirmation modal */

/* optional message modal  */
.optional_mesasge_popup {
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding: 0 10%;

  font-family: "Trocchi";
  font-size: 2.08vw;
  line-height: 3.5vw;
}
/* end optional message modal  */

/* ####### COMMAND PAGE ######*/
/* nav bar deesktop */
.content_section_command {
  height: auto;
  display: flex;
  flex-wrap: nowrap;
  width: auto;
  justify-content: space-between;
  clear: both;
  border: 4px solid #bfbc8e;
  min-height: 86vh;
  border: 0.26vw solid #bfbc8e;
  padding: 5.2vw 6.5vw 6.2vw 6.7vw;
}
.navs_bar_height_as_margin_top {
  margin-top: 4.41vw;
}
.navBarOverflowContainer {
  width: 100%;
  height: 102%;
  display: flex;
  flex-direction: row;
  align-items: center;

  overflow-x: auto;
  overflow-y: hidden;
}
.command_category_navbar {
  width: 54.28vw;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  height: 4.41vw;
  border-bottom: 0.26vw solid #bfbc8e;
  border-top: 0.26vw solid #bfbc8e;
  padding: 0 3.5vw;

  position: relative;
}
.command_category_navbar_sticky {
  position: fixed;
  top: 0;
  z-index: 999;
  border-top: 0 !important;
}
.navBarOverflowContainer::-webkit-scrollbar {
  background-color: transparent;
  height: 0;
  width: 0;
}
.navBarOverflowContainer::-webkit-scrollbar-track {
  background-color: transparent;
}
.navBarOverflowContainer::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.top_zero {
  top: 0;
  bottom: unset;
}
.comamnd_nav_bar_arrow {
  width: 3.5vw;
  height: 100%;
  display: flex;
  align-items: center;
  left: 0;
  top: 0;
  position: absolute;
  cursor: pointer;
}
.comamnd_nav_bar_arrow svg {
  width: 2vw;
  height: 1vw;
}
.comamnd_nav_bar_arrow.arrow_right {
  right: 0;
  left: unset;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.comamnd_nav_bar_arrow.arrow_right svg {
}
.command_title_category {
  font-size: 2.6vw;
  /* padding-top: 4vw; */
  color: white;
  font-family: "Trocchi";
  text-transform: capitalize;

  width: 100%;
}
.user_select_none {
  user-select: none;
  -moz-user-select: none;
}
.command_nav_bar_item {
  height: 100%;
  display: flex;
  align-items: center;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  position: relative;
  margin-right: 3.12vw;
  font-size: 1.14vw;
  color: #ffffff;
  font-family: "Trocchi";
  cursor: pointer;
  border-bottom: 0.26vw solid transparent;
  border-width: 0.4vw;
  white-space: nowrap;
}
.command_nav_bar_item > div {
  margin-top: 0.26vw;
}
.command_nav_bar_item.selected_nav_bar_item {
  border-bottom: 0.26vw solid #bfbc8e;
}

/* ####lower container */
.command_section_content_left {
}
.command_items_container {
  display: flex;
  flex-wrap: wrap;
  width: 54vw;
  justify-content: space-between;
}
.command_menu_list_item_column._right_margin_column_item {
  margin-right: 3.2vw;
}
.command_menu_list_item_column {
  width: 25vw;
  position: relative;
}
.command_menu_list_item_container {
  width: 100%;
  margin-top: 2.96vw;
  margin-bottom: 2.08vw;
  position: relative;
}
.command_menu_list_item_title {
  font-size: 1.56vw;
  margin-bottom: 1.56vw;
  color: #ffffff;
  font-family: "Trocchi";
}
.command_menu_list_item_desc {
  font-family: "Trocchi";
  font-size: 0.83vw;
  color: #ffffff;
  line-height: 1.4vw;
  /* max-height: 4.4vw; */
  margin-bottom: 1.3vw;
}
.command_menu_list_item_desc > p {
  margin:0;
}
.command_menu_list_item_actions_cont {
  display: flex;
  align-items: center;
  margin-bottom: 1.33vw;
}
.command_menu_list_item_price {
  padding-right: 1.5vw;
  font-size: 1.25vw;
  font-family: "Trocchi";
}
.command_menu_list_item_price.cutted_old_price___{
  font-size: 0.83vw;
  padding-right: 0.52vw;
  text-decoration: line-through;
}
.command_menu_list_item_button {
  font-family: "Trocchi";
  cursor: pointer;
  font-size: 0.83vw;
  padding: 0.52vw 1.5vw;
  color: #ffffff;
  text-decoration: none;
  outline: none;
  border: 0.26vw solid #bfbc8e;
  border-radius: 2.08vw;
  box-shadow: 0px 15px 25px 0px rgba(1, 1, 1, 0.15);
  outline: none;
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.command_menu_list_item_sub_button {
  display: inline-block;
}
.command_btn_close_cont {
  width: 0.88vw;
  height: 0.88vw;
  display: flex;
  margin-right: 0.52vw;
}
.command_btn_close_cont > svg {
  width: 0.88vw;
  height: 0.88vw;
}
.section_divider {
  height: 0.26vw;
  width: 100%;
  background-color: #262626;
}
._abs_bot_lf {
  position: absolute;
  bottom: 0;
  left: 0;
}
.abs_pos_divider {
  position: absolute;
  bottom: 0;
  left: 0;
}
/* ####RECEIPT command....................... */
.command_receipt_container {
  -webkit-tap-highlight-color: transparent;
  height: auto;
}
/* program receipt */
.command_receipt_forbiden_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 70px;
  /* padding-left: 10px;
  padding-right: 10px; */
  min-height: 568px;
  font-family: "Trocchi";
  text-align: center;
  color: white;
}
._payment_modal .command_receipt_forbiden_container,
.command_receipt_fixed .command_receipt_forbiden_container {
  min-height: unset;
}
.command_receipt_forbiden_title {
  font-size: 30px;
  margin-bottom: 55px;
  width: 100%;
}
.command_receipt_forbiden_list_item {
  font-size: 30px;
  margin-bottom: 20px;
}
.command_receipt_forbiden_list_item > div:first-child {
  width: 100%;
  line-height: 40px;
}
.command_receipt_forbiden_list_item > div:first-child {
}
._payment_modal.pop_up__ {
  max-width: unset;
  width: 75vw;
  height: 41vw;
  min-height: 600px;
}
.command_receipt_forbiden_container.pop_up__ {
  padding-top: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.command_receipt_forbiden_title.pop_up__ {
  font-size: 3.125vw;
  line-height: 4vw;
  margin-bottom: 3.125vw;
  width: 100%;
  /* 
  font-size: 60px;
  margin-bottom: 65px; */
}
.command_receipt_forbiden_list_item.pop_up__ {
  font-size: 2.08vw;
  margin-bottom: 1.56vw;
  line-height: 4vw;
  /* font-size: 40px;
  margin-bottom: 30px; */
}
.command_receipt_forbiden_list_item.pop_up__ > div:first-child {
  width: 100%;
  /* line-height: 40px; */
}
.command_receipt_forbiden_list_item.pop_up__ > div:first-child {
}

/* end program receipts */

.command_receipt_container_flex_col_end {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.command_receipt_sub_container {
  width: 26.56vw;
  border: 0.26vw solid #bfbc8e;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 700px;
  position: relative;
}
.command_receipt_sub_container_padding {
  padding-left: 1.5vw;
  padding-right: 1.5vw;
  padding-bottom: 16px;
}
.disabled_button_ {
  /* background-color: grey !important; */
  cursor: auto !important;
}
/* custom text for shipping */
.command_custom_text_shipping_pain ._r_total_conf_btn {
  padding-top: 0 !important;
  margin-top: 0;
}
.custom_delivery_text__ {
  display: flex;
  max-height: 78px;
  font-size: 16px !important;
  text-align: right;
}
.custom_delivery_text__ > div:nth-child(2) {
  width: 322px !important;
}
   /* when a long text for delivery is displayed and we are not on the submitting part*/
.command_custom_text_shipping_pain.command_custom_text_shipping_pain_garb .command_receipt_total_line._r_total_conf_btn{
  margin-top: 5px!important;
}
/* end custum text for shipping  */

.command_receipt_total_line {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-family: "Trocchi";
  color: white;
  box-sizing: border-box;
  margin: 2px 0;
}
.section_divider.mg_top_ {
  margin-top: 10px;
}
._r_total_price {
  padding-top: 10px;
  font-size: 22px;
}
._r_total_price > div:first-child {
  text-transform: uppercase;
}
/* receipt button */
._r_total_conf_btn {
  justify-content: center;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  margin-top: 0;
  padding-top: 0;
  height: 80px;
  /* height: 100%; */
  font-size: 24px;
  align-items: center;
}

/* agreement receipt desk */
.receipt_agreement{
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 0 0 0;
}
.agr_label_cls {
  font-size: 14px!important;
  width: calc(100% - 30px)!important;
}
.agr_square_cls {
  width: 19px!important;
  height: 19px!important;
  margin-right: 10px!important;
  margin-top: 1px;
}
.agr_check_cont_cls {
  height: 40px!important;
  width: 100%!important;
  margin-bottom: 0!important;
}
/* end agreement receipt desk */

/* agreement text bottom receipt */
.receipt_agreement_text {
  font-size: 15px;
  font-family: "Trocchi";
  width: 100%;
  margin-top: 5px;
  color: white;
  text-align: center;
}
.receipt_agreement_text > a {
  text-decoration: underline!important;
  cursor: pointer;
}
/* end agreement text bottom receipt */

.command_receipt_item_button_disabled_ {
  background-color: grey !important;
}
.command_receipt_item_button {
  color: black !important;
  width: 100% !important;
  height: 100% !important;
  font-size: 24px !important;
}
.command_receipt_item_button_sliced {
  color: black !important;
  width: calc(100% - 94px) !important;
  height: 100% !important;
  font-size: 24px !important;
}
.command_receipt_back_btn {
  width: 68px;
  height: 68px;
  margin-right: 14px;
  border: 0.26vw solid white;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.command_receipt_back_btn > svg {
  width: 21.25px;
  height: 14.18px;
}
.back_btn_svg_path_ {
  fill: none;
  stroke-miterlimit: 10;
  stroke-width: 5px;
}
/* end receipt button */
.command_receipt_downer_side .section_divider {
  height: 5px;
}
.arrow_svg__ {
  fill: none;
  stroke-miterlimit: 7;
  stroke-width: 50px;
}
/* ##upper side receipt */
.command_receipt_downer_side {
  height: 352px;
}
.command_receipt_upper_side {
  height: calc(100% - 352px);
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
}
/* command no agreement */
.command_no_agreement_ .command_receipt_downer_side {
  height: 250px!important;
}
.command_no_agreement_ .command_receipt_total_line._r_total_conf_btn{
  margin-top: 5px!important;
}
/* end command no agreement */

.min_order_text p {
  margin: 0;
}

.command_receipt_title {
  font-family: "Trocchi";
  font-size: 32px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  height: 85px;
  overflow: hidden;
  color: white;
  padding-top: 16px;
}
.command_receipt_title .command_receipt_tile_txt {
  width: 100%;
  text-align: center;
}
.command_receipt_tile_txt > span:not(.back_btn_mobile_collapse) {
  display: block;
}
.command_receipt_title_warning {
  font-size: 16px;
  /* margin-top: 7px; */
  color: white;
}
.command_receipt_title_warning.min_order_text {
  font-size: 13px;
}
.back_btn_mobile_collapse {
  display: none;
}
/* delivery formular */
.command_receipt_delivery > div {
  width: 100%;
  text-align: center;
}
.command_receipt_delivery .command_receipt_back_action_txt {
  font-size: 15px;
  margin-bottom: 15px;
  cursor: pointer;
}
.command_receipt_delivery_title {
}
.command_receipt_delivery_item_cont {
  position: relative;
  height: 48px;
  display: flex;
  align-items: center;

}
/*for dropdwon top calcultation */
.command_receipt_delivery_mg_el {
  height: 20px;
 }

 /*<asap forms> */
.command_receipt_delivery_item_cont .command_receipt_input {
  width: 100%;
  border: none;
  background-color: transparent;
  line-height: 5px;
  padding: 5px 0;
  outline: none;
  color: white;
  font-size: 16px;
  text-align: center;
  font-family: "Trocchi";
}
.command_receipt_delivery_item_cont {
  font-size: 16px;
}
.c_r_dropdown_selected_item {
  font-size: 16px;
  height: 55px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  cursor: pointer;
}
.choose_delivery_method_cont {
  /* height: 40x; */
  padding-top: 0;
}
.choose_delivery_method_explanation {
  width: 100%;
  text-align: left;
  font-family: "Trocchi";
  padding-top: 5px;
  font-size: 13px;
  position: relative;
  top: 9px;
}
.receipt_right_err_icon_form {
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  padding-right: 15px;
  padding-left: 15px;
  background-color: white;
}
.receipt_right_err_icon_form > svg{
  width: 14px;;
  height: 14px;
}
.c_r_dropdown_selected_item_arrow {
  padding-right: 15px;
  display: flex;
  justify-items: center;
  align-items: center;
}
.c_r_dropdown_selected_item_arrow > svg{
  width: 14px;;
  height: 14px;
}
.asap_delivery_option_cont {
  width: calc(100% - 5px);
  box-sizing: border-box;
  font-size: 16px;
  padding-top: 15px;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.asap_checkbox_cont {
  align-items: center!important;
}
.asap_checkbox_label {
  font-size: 14px!important;
}
.asap_square_checkbox {
  width: 22px!important;
  height: 22px!important;
}
.error_message_receipt {
  width: 100%;
  position: absolute;
  left: 0;
  /* top: 60px; */
  top: 107%;
  color: #ff3333;
  font-size: 16px;
  text-align: center;
  font-family: "Trocchi";
}
.r_c_time_selector_cont {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
}
.r_c_time_selector_cont > div {
  width: calc(50% - 10px);
  height: 100%;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.r_c_time_selector_date {

}
 /*</asap forms> */


.error_divider {
  background-color: #ff3333;
}
.command_receipt_items_delivery_container > div:last-child {
  font-size: 13px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
/* end delivery formular*/
.empty_message_item_receipt {
  height: 100px;
  width: 100%;
}
.command_receipt_items_container.command_receipt_items_delivery_container{
  max-height: calc(100% - 25px);
}
.command_receipt_items_container {
  width: 100%;
  font-size: 20px;
  color: white;
  font-family: "Trocchi";
  padding-right: 0;
  max-height: calc(100% - 83px);
  overflow-y: scroll;
  -webkit-overflow-scrolling: auto !important;
  transition: height 0.2s;
}
.command_receipt_item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 30px;
  width: 100%;
  align-items: center;
  font-size: 20px;
  font-family: "Trocchi";
}
.command_left_side_receipt {
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 75%;
  align-items: center;
}
.command_receipt_item_name {
  width: calc(100% - 70px);
  margin-right: 7px;

  white-space: nowrap;

  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
.command_receipt_item_qty {
  display: flex;
  height: 100%;
  align-items: center;
  min-width: 70px;
  justify-content: space-between;
}
.arthm_op_btn > svg {
  width: 14px;
}
.arthm_op_btn {
  cursor: pointer;
  height: 20px;
  width: 14px;
  display: flex;
  align-items: center;
}
.command_receipt_qty_num {
}
._user_select_none {
  user-select: none;
  -moz-user-select: none;
  -webkit-tap-highlight-color: transparent;
}
.command_receipt_item_price {
  width: auto;
}
/* ##fixed receipt setup */
.command_receipt_fixed {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
  padding-top: 1vw;
  padding-right: 6.5vw;
  height: 100vh;

}
.command_receipt_fixed .command_receipt_sub_container {
  height: 97%;
  min-height: unset;
}
.command_receipt_items_container {
  scrollbar-color: #262626 transparent;
  scrollbar-color: grey transparent;
}
.command_receipt_items_container::-webkit-scrollbar {
  background-color: transparent;
  width: 10px;
}
.command_receipt_items_container::-webkit-scrollbar-track {
  background-color: transparent;
}
.command_receipt_items_container::-webkit-scrollbar-thumb {
  background-color: grey;
}
.width_minus_scoll_bar_items {
  width: calc(100% - 10px)!important;
}
/* command mobile */
.command_mobile_cart_opener {
  display: none;
  width: 60px;
  height: 60px;
  filter: drop-shadow(0px 10px 30px 0px rgba(0, 0, 0, 0.25));
}
.command_mobile_cart_opener.hide_mobile_cart_opener {
  display: none !important;
}
.command_mobile_cart_opener_sub_container {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}
.command_mobile_cart_opener_sub_number {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-family: "Trocchi";
}
.command_mobile_cart_opener_sub_number > div {
  margin-top: 4px;
}
.command_purse_icon_container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.command_purse_icon_container > svg {
  fill: transparent;
  width: 50px;
  height: 50px;
}
.command_mobile_cart_opener .cyrcle_sv_command_opener {
  width: 60px;
  height: 60px;
}
.mob_only__ {
  display: none;
}
.desk_only__ {
  display: inline;
}
input::placeholder {
  overflow: visible;
}
.program_text h3 div {
  display: inline-block;
}
.program_text h3 > div > div {
  position: relative;
  top: 0.1vw;
}
.svg_program__ {
}
/* dropdown receipt */


/* end dropdown receipt */
/* command section media */
@media only screen and (max-height: 600px) and (max-width: 1120px) {
  #section1 {
    height: auto !important;
    min-height: 100vh !important;
  }
}
@media only screen and (max-width: 1700px) {

}
@media only screen and (max-width: 1850px) {
  .command_receipt_sub_container {
    width: 29.56vw;
    min-width: 400px;
  }
}
@media only screen and (max-width: 1400px) {
  .choose_delivery_method_cont {
    font-size: 16px;
    height: 35px;
    padding-top: 0;
  }
  /* .command_receipt_item {
    flex-wrap: wrap;
    height: auto;
    min-height: 26.95px;
    margin-bottom: 12px;
  } */
  .command_receipt_sub_container {
    width: 29.56vw;
    min-width: 320px;
  }
  
  /* more space for the form section */
  .command_left_side_receipt {
    width: 75%;
    min-width: unset;
  }
  .command_receipt_item_price {
    width: auto;
  }
  .command_receipt_item_name {
    width: calc(100% - 70px);
    min-width: unset;
    max-width: unset;
  }
  .command_receipt_title {
    font-size: 28px;
  }
  .command_receipt_title_warning {
    font-size: 13px;
    margin-top: 6px;
  }
  .command_receipt_title_warning.min_order_text {
    font-size: 13px;
  }
  ._r_total_price {
    padding-top: 5px;
    font-size: 20px;
  }
  .command_receipt_downer_side {
    height: 295px;
  }
  ._r_total_conf_btn {
    margin-top: 5px;
    height: auto;
  }
  .command_receipt_item {
    font-size: 15px;
  }
  /* receipt_button */
  .command_receipt_item_button {
    font-size: 18px !important;
  }
  .command_receipt_item_button_sliced {
    width: calc(100% - 75px) !important;
    height: 65px !important;
    font-size: 18px !important;
  }
  .command_receipt_back_btn {
    width: 60px;
    height: 60px;
    margin-right: 10px;
    border: 4px solid white;
    border-radius: 50px;
  }
  .command_receipt_back_btn > svg {
    width: 21.25px;
    height: 14.18px;
  }
  .back_btn_svg_path_ {
    fill: none;
    stroke-miterlimit: 10;
    stroke-width: 5px;
  }
  .command_receipt_item_button_disabled_ {
    background-color: grey !important;
  }
  .command_receipt_item_button {
    height: 65px !important;
    font-size: 18px !important;
  }
  /* end receitp button */
  .command_receipt_total_line {
    font-size: 16px;
  }
  .command_receipt_total_line._r_total_price {
    font-size: 20px;
  }
  .command_receipt_total_line._r_total_conf_btn {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  /* custom text for shipping */
  .command_custom_text_shipping_pain ._r_total_conf_btn {
    
  }
  .custom_delivery_text__ {
    display: flex;
    max-height: 70px;
    font-size: 13px !important;
  }
  .custom_delivery_text__ > div:nth-child(2) {
    width: 207px !important;
  }
  
   /* agreement receipt desk */
   .receipt_agreement{
    /* height: 40px;
    padding: 5px 0 3px 0; */
  }
  .agr_label_cls {
    font-size: 11px!important;
    width: calc(100% - 25px)!important;
  }
  .agr_square_cls {
    width: 15px!important;
    height: 15px!important;
    margin-right: 5px!important;
    margin-top: 1px;
  }
  
  .agr_check_cont_cls {
    height: 40px!important;
    width: 100%!important;
    margin-bottom: 0!important;
  }
  /* end agreement receipt desk */

  /* agreement text bottom receipt */
  .receipt_agreement_text {
    font-size: 10px;
    margin-top: 5px;
  }
  .receipt_agreement_text > a {
    text-decoration: underline!important;
    cursor: pointer;
  }
  /* end agreement text bottom receipt */




    /* command no agreement */
  .command_no_agreement_ .command_receipt_downer_side {
    height: 225px!important;
  }
  .command_no_agreement_ .command_receipt_total_line._r_total_conf_btn{
    margin-top: 12px!important;
  }
  /* end command no agreement */
  /* end custum text for shipping  */
   /* when a long text for delivery is displayed and we are not on the submitting part*/
  .command_custom_text_shipping_pain.command_custom_text_shipping_pain_garb .command_receipt_total_line._r_total_conf_btn{
    margin-top: 5px!important;
  }
  /* end custum text for shipping  */

  .command_receipt_items_delivery_container > div:last-child {
    font-size: 11px;
  }
 
  
  /*<asap forms> */
  .command_receipt_delivery_item_cont .command_receipt_input {
    width: 100%;
    line-height: 5px;
    padding: 5px 0;
    font-size: 13px;
  }
  .c_r_dropdown_selected_item {
    font-size: 13px;
    height: 35px;
  }
  .choose_delivery_method_cont {
    font-size: 13px;
    height: 40x;
  }
  .choose_delivery_method_explanation {
    font-size: 11px;
    padding-top: 10px;
    top: 3px;
  }
  .c_r_dropdown_selected_item_arrow {
    padding-right: 10px;
  }
  .c_r_dropdown_selected_item_arrow > svg{
    width: 14px;
    height: 14px;
  }
  .receipt_right_err_icon_form {
    padding-right: 15px;
    padding-left: 15px;
  }
  .receipt_right_err_icon_form > svg{
    width: 14px;;
    height: 14px;
  }
  .asap_delivery_option_cont {
    padding-top: 15px;
  }
  .asap_checkbox_label {
    font-size: 11px!important;
  }
  .asap_square_checkbox {
    width: 13px!important;
    height: 13px!important;
  }
  .error_message_receipt {
    width: 100%;
    left: 0;
    top: 107%;
    font-size: 16px;
  }
  .r_c_time_selector_cont {
    width: 100%;
    /* height: 25px; */
    /* padding-bottom: 5px; */
  }
  .r_c_time_selector_cont > div {
    width: calc(50% - 10px);
    height: 100%;
  }
  .r_c_time_selector_date {

  }
  .command_receipt_delivery_item_cont 
  .command_receipt_input,
  .error_message_receipt {
    font-size: 13px;
  }
  .command_receipt_delivery_item_cont {
    font-size: 13px;
  }
 /*</asap forms> */


}
@media only screen and (max-width: 1190px) {
  .command_receipt_fixed {
    padding-right: 5.5vw;
  }
}
@media only screen and (max-width: 1024px) {
  .select-items::-webkit-scrollbar {
    width: 12px;
  }
  .powerd_by {

    bottom: 0;
  }
  .command_receipt_item {
    padding-right: unset;
  }
  #section_command {
    position: relative;
  }
  .mob_only__ {
    display: inline;
  }
  .desk_only__ {
    display: none;
  }
  /* program receipt */
  .command_receipt_forbiden_container.pop_up__,
  .command_receipt_forbiden_container {
    padding-top: 0;
    min-height: unset;
  }
  .command_receipt_forbiden_title.pop_up__,
  .command_receipt_forbiden_title {
    font-size: 22px;
    line-height: unset;
    margin-bottom: 35px;
  }
  .command_receipt_forbiden_list_item.pop_up__,
  .command_receipt_forbiden_list_item {
    font-size: 18px;
    margin-bottom: 15px;
  }
  .command_receipt_forbiden_list_item.pop_up__,
  .command_receipt_forbiden_list_item > div:first-child {
    line-height: 28px;
  }
  .command_receipt_forbiden_list_item.pop_up__,
  .command_receipt_forbiden_list_item > div:first-child {
    line-height: unset;
  }
  /* end program receipts */
  .command_mobile_cart_opener {
    display: initial;
    position: absolute;
    top: 132px;
    right: 25px;
    z-index: 9999;
  }
  .command_mobile_cart_opener_placed_down {
    top: unset;
    bottom: 40px;
  }
  .command_mobile_cart_opener_fixed {
    position: fixed;
    top: calc(50vh - 30px);
  }

  .command_section_content_left {
    width: 100%;
  }
  .command_items_container {
    width: 100%;
  }
  .command_menu_list_item_column {
    width: 100%;
  }
  .command_menu_list_item_container {
    width: 100%;
    height: auto;
    margin-top: 25px;
    margin-bottom: 30px;
  }
  .command_menu_list_item_title {
    font-size: 20px;
    margin-bottom: 25px;
    line-height: 32px;
    width: 90%;
  }
  .command_menu_list_item_desc {
    font-size: 13px;
    line-height: 25px;
    height: auto;
    max-height: unset;
    margin-bottom: 26px;
  }
  .command_title_category {
    font-size: 28px;
    padding-top: 50px;
    color: white;
    font-family: "Trocchi";
    text-transform: capitalize;
    width: 100%;
  }
  .command_menu_list_item_actions_cont {
    display: flex;
    align-items: center;
    margin: 15px 0;
  }
  .section_divider._abs_bot_lf {
    position: unset;
    height: 4px;
  }
  .command_menu_list_item_price {
    padding-right: 32px;
    font-size: 18px;
    font-family: "Trocchi";
  }
  .command_menu_list_item_price.cutted_old_price___{
    font-size: 12px;
    padding-right: 10px;
    margin-top: 1px;
  }
  .command_menu_list_item_button {
    font-size: 13px;
    padding: 10px 15px;
    border: 5px solid #bfbc8e;
    border-radius: 25px;
    box-shadow: 0px 15px 25px 0px rgba(1, 1, 1, 0.15);
    margin: 0;
  }
  .command_menu_list_item_sub_button {
    display: inline-block;
  }
  .command_menu_list_item_sub_button_disabled {
  }
  .command_btn_close_cont {
    width: 14px;
    height: 14px;
    display: flex;
    margin-right: 10px;
  }
  .command_btn_close_cont > svg {
    width: 14px;
    height: 14px;
  }
  .section_divider {
    height: 3px;
    width: 100%;
    background-color: #262626;
  }
  .error_divider {
    background-color: #ff3333;
  }
  ._abs_bot_lf {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  /* navbar */
  .navs_bar_height_as_margin_top {
    margin-top: 4.41vw;
  }
  .navBarOverflowContainer {
  }
  .command_category_navbar {
    width: 100%;
    height: 60px;
    border-bottom: 4px solid #bfbc8e;
    border-top: 4px solid #bfbc8e;
    padding: 0 28px;
    margin-bottom: 0;
  }
  .top_zero {
  }
  .comamnd_nav_bar_arrow {
  }
  .comamnd_nav_bar_arrow.arrow_right {
  }
  .comamnd_nav_bar_arrow.arrow_right svg {
  }
  .command_nav_bar_item {
    margin-right: 20px;
    font-size: 16px;
    border-bottom: 4px solid transparent;
  }
  .command_nav_bar_item > div {
    margin-top: 4px;
    font-size: 14px;
  }
  .command_nav_bar_item.selected_nav_bar_item {
    border-bottom: 4px solid #bfbc8e;
  }
  /* right receipt */
  .command_receipt_container {
    display: none;
  }
  .command_category_navbar.command_category_navbar_sticky {
    width: 86.9% !important;
    border-top: 0 !important;
  }
  .show_cart_command_for_mobile {
    display: initial;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99999;
    display: flex;
    justify-content: center;
    padding: 1vw 0;
    align-items: center;

    min-height: 500px;
    transition: height 0.1s;
  }

  .show_cart_command_for_mobile .command_receipt_sub_container {
    min-width: 320px;
    width: 90vw;
    height: 100%;
  }
  .command_receipt_tile_txt {
    position: relative;
  }
  .command_receipt_tile_txt .back_btn_mobile_collapse {
    /* position: absolute; */
    display: initial;
    /* width: 50px;
    font-size: 40px;
    height: 100%;
    top: -7px;
    left: 0; */
  }
  .command_receipt_sub_container_padding {
    padding-left: 20px;
    padding-right: 20px;
  }
  .command_receipt_items_container {
    width: 100%;
  }

  .command_receipt_items_container::-webkit-scrollbar {
    background-color: transparent;
    width: 5px!important;
  }
  .width_minus_scoll_bar_items {
    width: calc(100% - 5px)!important;
  }
  /* modal  */
  .append-element-container {
  }
  ._payment_modal {
    /* width: -webkit-calc(100vw - 20px);
    width: -moz-calc(100vw - 20px); */
    width: calc(100vw - 20px);

    /* height: -webkit-calc(100vh - 10px);
    height: -moz-calc(100vh - 10px); */
    height: calc(100vh - 10px);

    height: 482px;
    justify-content: flex-start;
    align-items: flex-start;
    min-width: unset;
    max-width: unset;
    max-height: unset;
    padding: 20px;
  }
  .payment_conf_modal_container {
    min-width: unset;
    min-height: unset;
  }
  .paymentPopupStyle p,
  .popup_paragraf {
    font-size: 22px;
    line-height: 38px;
    padding-left: 35px;
    padding-right: 35px;
  }
  .popup_paragraf_failed {
    line-height: 38px;
  }
  .paymentPopupStyle h1,
  .popup_paragraf_title {
    font-size: 33px;
    margin-bottom: 0;
  }
  .pop_up_close_btn {
    width: 20px;
    height: 20px;
    top: 30px;
    right: 30px;
  }
  .popup_paragraf_mob {
    display: none;
  }
  .pop_up_close_btn > svg {
    width: 27px;
    height: 27px;
  }
  .btn_container____ {
    margin-top: 23px;
    height: 90px;
  }
  .sub_btn____ {
    padding: 15px 50px;
    font-size: 18px;
    line-height: 30px;
    color: black !important;
  }
  .popup_paragraf_mob {
    display: initial;
  }
  .popup_paragraf_desk {
    display: none;
  }
  ._payment_modal.pop_up__ {
    max-width: unset;
    width: 100vw;
  }

  .comamnd_nav_bar_arrow {
    width: 28px;
    height: 100%;
  }
  .comamnd_nav_bar_arrow svg {
    width: 50%;
    height: 100%;
  }
  .arrow_svg__ {
    stroke-miterlimit: unset;
    stroke-width: 35px;
  }
  .no_border_radius_mobile_ {
    border-radius: 0;
  }

  /* age confirmation modal */
  .age_conf_modal_sub_container {
    width: 225px;
    height: 335px;
  }
  .age_conf_modal_title {
    font-size: 33px;
  }
  .age_conf_modal_content {
    font-size: 22px;
  }
  .age_conf_modal_btns_container {
    height: 120px;
    flex-direction: column;
    justify-content: space-between;
  }
  /* end confirmation modal */

  /* optional message modal  */
  .optional_mesasge_popup {
    padding: 0 10%;
    font-size: 22px;
    line-height: 35px;
  }
  /* end optional message modal  */

  .program_text h3 > div > div {
    position: relative;
    top: 5px;
  }

  /* <dropdown_datepicker> */
  .dropdown_datepicker_popper{
    padding: 0;
  }
  /* </dropdown_datepicker> */ 

  .contact_info_svg {
    width: 30px;
    height: 30px;
    margin-right: 8px;
  }
}
@media only screen and (max-width: 1024px) and (max-height: 1366px) {
  .show_cart_command_for_mobile .command_receipt_sub_container {
    height: 97%;
  }
}
/* end mobile 1024....... */
@media only screen and (max-width: 500px) {
  .info_location {
    padding: 0 !important;
  }
  #section1 .description {
    padding: 0 20px;
  }
}
@media only screen and (max-height: 700px) and (max-height: 1366px) {
  .command_receipt_sub_container {
    min-height: 90%;
  }
}
@media only screen and (max-width: 768px){
  .slick-slide {
    height: 400px;
  }
  .slick-list {
    height: 400px;
  }
  .slick-slide > div > div{
    display: flex!important;
    align-items: center;
    justify-content: center;
    height: 400px;
  }
  .program_text h3 > div > div {
    top: 1.3vw!important;
  }
}
@media only screen and (max-width: 400px) {
  .show_cart_command_for_mobile .command_receipt_sub_container {
    min-width: unset;
  }
  .email_contact {
    overflow: hidden;
  }
  .command_receipt_title_warning.min_order_text {
    font-size: 10px;
  }
    /* receipt_button */
    .command_receipt_item_button {
      font-size: 16px !important;


    }
    .command_receipt_item_button_sliced {
      font-size: 16px !important;
      white-space: normal!important;
      text-align: center!important;
    }
    
}
@media only screen and (max-width: 400px) {
  .show_cart_command_for_mobile .command_receipt_sub_container {
    min-width: unset;
  }
  .email_contact {
    overflow: hidden;
  }
}
@media only screen and (max-height: 950px) {
  .__feedback__modal {
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 220px) {
  /* .command_category_navbar.command_category_navbar_sticky {
    width: 90.9% !important;
    left: 4%!important;
  } */
}


/* @media only screen and (min-width: 320px) and (max-height: 600px){
  ._payment_modal.pop_up__{
    min-height: 100vh;
  }
} */
/**/




.error_red_bg_color {
  background-color: #ff3333!important;
}
.error_red_color {
  color: #ff3333!important;
}
.error_red_color_fill {
  fill: #ff3333!important;
}

.react-pin-image {
  height: 50px;
  width: 50px;
  position: relative;
  top: -50px;
  left: -16px;
}





.command_receipt_item-enter {
  opacity: 0;
}
.command_receipt_item-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.command_receipt_item-exit {
  opacity: 1;
}
.command_receipt_item-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}


#menu-layout-container {
  width: 100vw;
  height: 100vh;
  overflow: auto;
  position: relative;
  top: 0;
  left: 0;
  z-index: 888;
  background-color: #fdf0e8;
  flex:1;
}