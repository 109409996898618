.payment-form-container {
    position: relative;
    font-family: "Trocchi";
    .section_divider {
        margin-top: 5px;
    }
    .payment-form-percentages {
        width: 100%;
        display: flex;
        margin-bottom: 5px;
        margin-top: 25px;
        .check_box_container__ {
            margin-right: 20px!important;
        }
    }
    .text-tip {
        font-size: 14px!important;
        line-height: 20px!important;
    }
}
@media screen and (max-width: 1580px) { 
    .payment-form-container {
        .text-tip {
            font-size: 11px!important;
            line-height: 15px!important;
        }
    }
}
@media screen and (max-width: 1400px) { 
    .payment-form-container {
        .section_divider {
            height: 5px;
        }
        .payment-form-percentages {
            margin-top: 15px;
            .check_box_container__ {
                margin-right: 15px!important;
            }
        }
    }
}
@media screen and (max-width: 1290px) { 
    .payment-form-container {
        .text-tip {
            font-size: 9px!important;
            line-height: 12px!important;
        }
    }
}
@media screen and (max-width: 1024px) { 
    .payment-form-container {
        .text-tip {
            font-size: 11px!important;
            line-height: 15px!important;
        }
    }
}