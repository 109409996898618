
#page_footer a{
    text-decoration: none;
    color: unset;
    outline: none;
    -webkit-tap-highlight-color: transparent;
}
.footer_subcontainer {
    height: 10.52vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 6vw;
    font-family: "Work Sans Bold";
    background-color: white;
}
.footer_item_cont > div {
    color: black;
    font-size: 0.93vw;
}
.footer_item_cont > div:first-child {
    color: #939393;
}
.footer_item_cont > div:nth-child(2) {
    margin-top: 1vw;
}
.footer_middle_divider{
    width: 86.77vw;
    margin-left: 6.21vw;
    height: 0.05vw;
    background-color: #f2f2f2;
}
.footer_bottom_container {
    height: 4.94vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.93vw;
    color: #939393;
    font-family: "Work Sans Bold";
}
.footer_bottom_container .footor_bottom_it.mg__ {
    margin-right: 4.3vw;
}
.footer_logo_container {
    display: flex;
    height: 5vw;
    align-items: center;
}
.footer_img_cont img {
    height: 0.89vw;
    margin-top: 0.3vw;
    margin-left: 0.34vw;
}

.footer_text_by__ {
    font-size: 0.93vw;
    color: #939393;
    line-height: 1vw;
}
.footor_bottom_it a{
    display: flex;
    justify-content: center;
    align-items: center;
}
@media only screen and (max-width: 1024px) {
    .footer_subcontainer{
        padding: 17px 40px;
        height: auto;
    }
    .footer_item_cont {
        width: 100%;
        margin-bottom: 17px;
    }
    .footer_item_cont > div {
        font-size: 12px;
        line-height: 17px;
    }
    .footer_middle_divider{
        margin-left: 40px;
        height: 1px;
    }
    .footer_bottom_container {
        font-size: 12px;
        height: 50px;
    }
    .footer_img_cont img {
        height: 11px;
        margin-top: 3px;
        margin-left: 4px;
    }
    .footer_bottom_container .footor_bottom_it.mg__ {
        margin-right: 40px;
    }
    .footor_bottom_it {
        font-size: 12px;
    }
    .footer_text_by__ {
        font-size: 12px;
    }
}