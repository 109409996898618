.fade-abs-cont {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    overflow: hidden;
    &> div:first-child,
    &> div:nth-child(2) { 
        width: 150%;
        height: 50%;
        position: relative;
        -webkit-transform: scaleX(-1);


    }
    &> div:first-child { 
        top: -50%;
        left: -25%;

         //this is overwritten in js       
        -webkit-box-shadow: 0 32vh 24vh white;

        
        -moz-box-shadow: 0 32vh 24vh white;
        box-shadow: 0 32vh 24vh white;
      }
    &> div:nth-child(2) { 
        bottom: -50%;
        left: -32%;
        -webkit-transform: scaleX(-1);
        transform: rotate(180deg);
        //this is overwritten in js
        -webkit-box-shadow: 0 20vh 24vh white;
        -moz-box-shadow: 0 20vh 24vh white;
        box-shadow: 0 20vh 24vh white;
    }
}

