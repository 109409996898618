.bundle-assoc-item-sub-list {
  font-family: "Trocchi";
  font-size: 13px;
  width: 100%;
  text-align: left;
  padding-top: 7px;
}
@media only screen and (max-width: 1400px) {
  .bundle-assoc-item-sub-list {
    font-size: 11px;
  }
}

.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.example-appear {
  opacity: 0.01;
}

.example-appear.example-appear-active {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

.emptySpace {
  height: 45px;
}

/** UTILS **/
.position-absolute {
  position: absolute;
}
.absolute-top {
  top: 0;
}
.absolute-right {
  right: 0;
}
.cursor-pointer {
  cursor: pointer;
}
.position-relative {
  position: relative;
}