.promo-container {
  position: relative;
  font-family: "Trocchi";
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;

  .promo-submit-button {
    width: auto !important;
    padding: 0 25px;
    height: 45px !important;
    font-size: 18px !important;
  }
  .promo-input-container {
    flex: 1;
    padding-right: 20px;
    input {
      width: 100%;
      height:45px;
      border-radius: 20px;
      font-family: "Trocchi";
        
      -webkit-tap-highlight-color: transparent;
      outline: none;
      padding: 0 20px;
      text-align: left;
      box-sizing: border-box;
      border: 2px solid white;
    }
  }
}
.promo-left-container {
    display: flex;
    align-items: center;
    & > div:first-child {
      display: flex;
      align-items: center;
      .promo-svg-delete {
        padding: 5px 10px;
        position: relative;
        cursor: pointer;
      }
    }
}
@media screen and (max-width: 1400px) {
  .promo-container {
    .promo-submit-button {
      padding: 0 15px;
      font-size: 16px !important;
    }
    .promo-input-container {
      padding-right: 10px;
      input {
        width: 100%;
        height: 45px;
        border-radius: 20px;
        padding: 0 10px;
        border: 2px solid white;
      }
    }
  }
}
