// //UPPER SIDE DESKTOP
.command_receipt_upper_side-desktop {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    .command_receipt_title {
        height: auto!important;
        p {
            margin: 5px 0!important;
        }
        padding-bottom: 7px;
    }
    .command_receipt_items_container  {
        max-height: unset!important;
        height: unset!important;
        flex: 1 1;
    }

} 
// //end GLOBAL    

// MOBILE 
.upperside_receipt_mobile_ {
    height: auto!important;
    max-height: unset!important;
    .command_receipt_title {
        height: auto!important;
        padding-bottom: 12px;
    }
}
.downer_side_receipt_mobile_ {
    height: auto!important;
}
.downer_side_receipt_mobile_ > div {
    margin-bottom: 10px!important;
}
.items_container_receipt_mobile_ {
    max-height: unset!important;
    height: auto!important;
}
.container_receipt_mobile_ {
    overflow: auto!important; 
    align-items: flex-start!important;
    min-height: unset!important;
}
.container_receipt_mobile_ .command_receipt_sub_container {
  height: auto!important;
  min-height: 97%!important;
  
  
  //edits new
//   min-height: 100vh!important;
  
}



//desktop
.downer_side_flex {
    height: auto;
}
















/* #1 STEP of receipt (with products) */
.items_container_fr_step {
    max-height: calc(100% - 91px)!important;
}
/* no delivery_row */
.upperside_receipt_fr_step_no_del_row {
    height: calc(100% - 200px)!important;
}
.downer_side_receipt_fr_step_no_del_row {
    height: 200px;
}
/* with delivery_row */
            /*(normal)*/
.upperside_receipt_fr_step_with_del {
    height: calc(100% - 250px)!important;
}
.downer_side_receipt_fr_step_with_del {
    height: 250px;
}
            /*(with a custom text)*/
.upperside_receipt_fr_step_with_del_as_text {
    height: calc(100% - 270px) !important;
}
.downer_side_receipt_fr_step_with_del_as_text {
    height: 270px;
}
/* end  #1 STEP step of receipt (with products) */


/* second step of receipt (shipping form) */
.items_container_sec_step {
    max-height: calc(100% - 25px)!important;
}
/* no delivery_row */
.upperside_receipt_sec_step_no_del_row {
    height: calc(100% - 296px)!important;
}
.downer_side_receipt_sec_step_no_del_row {
    height: 296px;
}
/* with delivery_row */
            /*(normal)*/
.upperside_receipt_sec_step_with_del {
    height: calc(100% - 303px)!important;
}
.downer_side_receipt_sec_step_with_del {
    height: 303px;
}
            /*(with a custom text)*/
.upperside_receipt_sec_step_with_del_as_text {
    height: calc(100% - 370px) !important;
}
.downer_side_receipt_sec_step_with_del_as_text {
    height: 370px;
}
/* end second step of receipt (shipping form) */


.cart-total-line-sub-info {
    font-size: 13px;
    font-family: "Trocchi";
    
    @media only screen and (max-width: 1050px) {
        font-size: 11px;
    }
}
