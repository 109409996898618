.simple-dropdown-container {
    font-family: "Trocchi";
    position: relative;
    .asb-dropdown-parent {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
    }

    .option-select-label-selected {
        cursor: pointer;
        max-height: 19.114583vw;
        text-align: center;
        font-family: "Trocchi";
        font-size: 1.25vw;
        color: #ffffff;
        font-weight: 100;
        background: transparent;
        border-top: 0vw;
        border-left: 0vw;
        border-right: 0vw;
        outline: none;
        border-bottom: 6px solid rgb(191, 188, 142);
        line-height: 1.7vw;
        padding-bottom: 0;
        position: relative;
        .c_r_dropdown_selected_item_arrow {
            position: absolute;
            right: 0;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
        }
        &.option-selected-dropdown-open {
            .c_r_dropdown_selected_item_arrow {
                -webkit-transform: scaleY(-1);
                transform: scaleY(-1);
            }
        }
    }
    .relative-dropdown-items {
        position: relative;
        top: 0
    }
    .simple-dropdown-option-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        max-height: 19.114583vw;
        overflow-y: scroll;
        background-color: black;
        color: white;
        
        user-select: none;
        border: 6px solid #bfbc8e;
        margin-left: -0.9375vw;
        
        z-index: 99;
        overflow-y: scroll;


        text-align: left;
        font-size: 1.25vw;
        left: 0;
        width: 120%;
        & > div:first-child {
            padding-top: .625vw;;
        }
        box-sizing: border-box;
        &::-webkit-scrollbar-track {
            /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
            background-color: #111111;
          }
          
          &::-webkit-scrollbar {
            width: 5px;
          }
          
          &::-webkit-scrollbar-thumb {
            /* background-color: #bfbc8e; */
            border: 2.258333vw solid rgba(0, 0, 0, 0);
            background-clip: padding-box;
            border-right: 0.8333333333333334vw solid rgba(0, 0, 0, 0);
            border-top: 1.40625vw solid rgba(0, 0, 0, 0);
          }


        .simple-dropdown-option {
            padding-bottom: 5px;
            color: #bfbc8e;
            padding-left: 5px;
            padding-bottom: 5px;

            line-height: 22px;
            font-size: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;
            &:hover {
                color: #ffffff;
            }

        }
        &>.simple-dropdown-option:first-child {
            padding-top: 10px;
        }
        .simple-dropdown-option.option-selected {
    
        }
    }
}

@media (max-width: 1024px) { 
    .simple-dropdown-container {
        position: relative;
        font-family: "Trocchi";

        .relative-dropdown-items {
            top: -10px;
        }
    
        .option-select-label-selected {
            font-size: 15px;
            line-height: 25px;
            border-bottom: 6px solid rgb(191, 188, 142)
        }
        .simple-dropdown-option-container {
           
            top: 10px;
            &::-webkit-scrollbar-track {
               
              }
              
              &::-webkit-scrollbar {
                width: 12px;
              }
              
              &::-webkit-scrollbar-thumb {

              }
    
    
            .simple-dropdown-option {
                color: #ffffff;
                cursor: pointer;
                user-select: none;
                padding-left: 5px;
            }
            &>.simple-dropdown-option:first-child {
           
            }
            .simple-dropdown-option.option-selected {
        
            }
        }
    }
    
}
@media (max-width: 758px) { 
    .simple-dropdown-container {
        position: relative;
        font-family: "Trocchi";
    
    
    
        .option-select-label-selected {
            font-size: 16px;
            border-bottom: 6px solid #bfbc8e;
            width: 100%;
        }
        .simple-dropdown-option-container {
            max-height: 284px;
            margin-left: 0;
    
            &::-webkit-scrollbar-track {
               
              }
              
              &::-webkit-scrollbar {
              
              }
              
              &::-webkit-scrollbar-thumb {

              }
    
    
            .simple-dropdown-option {
                padding: 7px 20px !important;
                text-align: center;
            }
            &>.simple-dropdown-option:first-child {
           
            }
            .simple-dropdown-option.option-selected {
        
            }
        }
    }
    
}