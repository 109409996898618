.generic_button_container {
  width: 17.96vw;
  height: 4.16vw;
  font-size: 1.25vw;
  border: 0.26vw solid white;
  border-radius: 50px;
  box-shadow: 0px 15px 25px 0px rgba(1, 1, 1, 0.15);
  color: white;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Trocchi";
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  outline: none;
  user-select: none;
  -moz-user-select: none;
  font-weight: normal;
  text-decoration: none;
  white-space: nowrap;
}
/* animation */
@keyframes gen_btn_animation_keyframe {
    0% {
      border-radius: 2.08vw;
    }
    25% {
      border-radius: 1.08vw;
    }
    30% {
      border-radius: 0;
    }
    95% {
      border-radius: 0;
    }
    100% {
      border-radius: 0;
    }
}
@keyframes gen_btn_animation_keyframe_mob {
0% {
    border-radius: 4px;
}
25% {
    border-radius: 2px;
}
30% {
    border-radius: 0;
}
95% {
    border-radius: 0;
}
100% {
    border-radius: 0;
}
}
.gen_btn_anim:hover {
  animation-name: gen_btn_animation_keyframe;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: forwards;
}
/* end animation */
.var_outline_ {
  background-color: white;
  color: black;
  border: 0;
}
.disabled___ {
  cursor: auto !important;
  background-color: grey !important;

}
.disabled___.var_outline_ {
  background-color: grey !important;
  
}
.disabled___:not(.var_outline_) {
  /* border-color: white !important; */
}

@media only screen and (max-width: 1024px) {
  .generic_button_container {
    border-width: 4px;
    width: 222px;
    height: 55px;
    font-size: 18px;
  }
  .gen_btn_anim:hover {
    animation-name: gen_btn_animation_keyframe_mob;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: forwards;
  }
}
