.command_receipt_item-enter {
  opacity: 0.01;
}

.command_receipt_item-enter.command_receipt_item-enter-active {
  opacity: 1;
  transition: opacity 100ms ease-in;
}

.command_receipt_item-leave {
  opacity: 1;
}

.command_receipt_item-leave.command_receipt_item-leave-active {
  opacity: 0.01;
  transition: opacity 100ms ease-in;
}

.command_receipt_item-appear {
  opacity: 0.01;
}

.command_receipt_item-appear.command_receipt_item-appear-active {
  opacity: 1;
  transition: opacity 0.1s ease-in;
}
