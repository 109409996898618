.tips-container {
    position: relative;
    font-family: "Trocchi";
    .section_divider {
        margin-top: 5px;
    }
    .tips-percentages {
        width: 100%;
        display: flex;
        margin-bottom: 10px;
        .check_box_container__ {
            margin-right: 20px!important;
        }
    }
    .text-tip {
        font-size: 20px!important;
        line-height: 20px!important;
    }
    .tips-main-text {
        margin-top: 25px;
        margin-bottom: 20px;
    }
}
@media screen and (max-width: 1400px) { 
    .tips-container {
        .section_divider {
            height: 5px;
        }
        .tips-percentages {
            .check_box_container__ {
                margin-right: 15px!important;
            }
        }
        
        .text-tip {
            font-size: 15px!important;
            line-height: 15px!important;
        }
        .tips-main-text {
            margin-top: 15px;
            margin-bottom: 13px;
        }
        
    }
}