.product_image_container {
  width: 100%;
  height: 12.5vw;
  margin-bottom: 0.85vw;
}
.product_image_container {
  .obj_fit_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.fallback-product-image {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
}

.-product-item-container {
  font-family: "Trocchi";
  .-product-ambalaj- {
    margin-bottom: 0.8vw;
    margin-top: -0.8vw;
  }
  .command-menu-list-item-optional {
    font-size: 0.83vw;
    line-height: 1.4vw;
    & > div:first-child {
      margin-bottom: 0.7vw;
    }
    .product-optional-list-cont {
      display: flex;
      flex-wrap: wrap;
      .check_box_container__ {
        margin-bottom: 0.67vw !important;
        margin-right: 1.3vw;
        .check_box_label__ {
          width: auto;
          font-size: 0.83vw;
          line-height: 1.4vw;
        }
        .check_box_square__ {
          margin-right: 0.5vw !important;
          width: 1.05vw !important;
          height: 1.05vw !important;
        }
      }
      /* SIZE DROPDOWN */
      .simple-dropdown-container {
        min-width: 130px;
        .relative-dropdown-items {
          min-width: 130px;
          height: 0px;
        }
        .simple-dropdown-option-container {
          width: 130px;
          margin-left: 0;
          .simple-dropdown-option {
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 10px;
            font-size: 0.83vw;
            line-height: 1.4vw;
            height: calc(1.4vw + 6px);
          }
        }
        .option-select-label-selected {
          font-size: 0.83vw;
          line-height: 1.4vw;
          height: calc(1.4vw + 6px);
        }
      }
    }
  }
}
@media only screen and (max-width: 1700px) {
  .command_menu_list_item_desc {
    font-size: calc(0.83vw * 1.1);
    line-height: calc(1.2vw * 1.35);
  }
  .command-menu-list-item-optional {
    font-size: calc(0.83vw * 1.1);
    line-height: calc(1.4vw * 1.35);
    & > div:first-child {
      margin-bottom: 0.7vw;
    }
    .product-optional-list-cont {
      display: flex;
      flex-wrap: wrap;
      .check_box_container__ {
        margin-right: 1.3vw;
        .check_box_label__ {
          width: auto;
          font-size: 0.83vw;
          line-height: 1.4vw;
        }
        .check_box_square__ {
          margin-right: 0.5vw !important;
          width: 1.05vw !important;
          height: 1.05vw !important;
        }
      }
    }
  }
}
@media only screen and (max-width: 1490px) {
  .product_image_container {
    height: 245px;
    margin-bottom: 18px;
  }
  .command_menu_list_item_desc {
    margin-bottom: 0.8vw;
  }
  .-product-ambalaj- {
    margin-top: 0 !important;
  }
  .-product-item-container {
    .command-menu-list-item-optional {
      & > div:first-child {
        margin-bottom: 0.3vw;
      }
    }
  }
}
@media only screen and (max-width: 1024px) {
  .command_menu_list_item_desc {
    font-size: 13px;
    line-height: 25px;
  }
  .-product-item-container {
    .command-menu-list-item-optional {
      font-size: 13px;
      line-height: 25px;
      & > div:first-child {
        margin-bottom: 15px;
      }
      .product-optional-list-cont {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        .check_box_container__ {
          margin-right: 25px;
          margin-bottom: 13px !important;
          .check_box_label__ {
            font-size: 13px;
            line-height: 25px;
          }
          .check_box_square__ {
            margin-right: 8px !important;
            width: 20px !important;
            height: 20px !important;
          }
        }
        /* SIZE DROPDOWN */
        .simple-dropdown-container {
          min-width: auto;
          .relative-dropdown-items {
            min-width: auto;
            height: 0px;
          }
          .simple-dropdown-option-container {
            width: auto;
            max-width: 100%;
            margin-left: 0;
            .simple-dropdown-option {
              padding-top: 10px;
              padding-bottom: 10px;
              font-size: 13px;
              line-height: 25px;
              height: calc(25px + 6px);
            }
          }
          .option-select-label-selected {
            font-size: 13px;
            line-height: 25px;
            height: calc(25px + 6px);
          }
        }
      }
    }
  }
}

.assoc-category-popup-main-wrapper {
  * {
    box-sizing: border-box;
    font-family: "Trocchi";
  }
  width: 754px;
  max-width: 100%;
  padding: 40px;
  position: relative;
  .assoc-close-btn {
    width: 42px;
    height: 42px;
    svg {
      width: 25px;
      height: 25px;
    }
  }
  .assoc-category-popup-inner {
    width: 100%;
    border-width: 5px;
    border-style: solid;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 60px;
    padding-bottom: 80px;
    .assoc-category-popup-image-wrapper {
      width: 100%;
      height: 245px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .assoc-category-popup-title {
      font-size: 32px;
      line-height: 49px;
      padding-top: 37px;
    }

    .assoc-category-popup-price {
      font-size: 24px;
      line-height: 34px;
      padding-bottom: 9px;
      padding-top: 0;
    }

    .assoc-category-popup-description {
      font-size: 16px;
      line-height: 24px;
      padding-bottom: 40px;
      border-bottom-width:  5px;
      border-bottom-style:  solid;

    }

    .assoc-category-popup-category-item {
      width: 100%;
      padding-top: 57px;
    }
    .assoc-category-popup-category-title {
      font-size: 20px;
      line-height: 20px;
      padding-bottom: 16px;
    }
    .assoc-category-popup-category-desc {
      font-size: 16px;
      line-height: 24px;
      padding-bottom: 22px;
    }
    .assoc-category-popup-category-check {
      height: 67px;
      width: 100%;
      border-bottom-width: 5px;
      border-bottom-style: solid;
      padding-left: 22px;
      display: flex;
      align-items: center;
      .check_box_square__ {
        width: 24px;
        height: 24px;
        svg {
          width: 24px;
          height: 24px;
        }
      }
      .check_box_label__ {
        min-height: 24px;
        line-height: 24px;
        padding-left: 20px;
      }
      .check_box_container__ {
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
    .assoc-category-popup-add-btn {
      .assoc-category-popup-error {
        height: 70px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        width: 100%;
        padding-bottom: 15px;
        color: red;
        font-size: 17px;
        
      }
      width: 100%;
      .generic_button_container {
        width: 100%;
        height: 90px;
      }
    }

  }
}

@media only screen and (max-width: 1024px) {
  .assoc-category-popup-main-wrapper {
    padding: 10px;
    .assoc-close-btn {
      width: 10px;
      height: 10px;
      svg {
        width: 10px;
        height: 10px;
      }
    }
    .assoc-category-popup-inner {
      border-width: 3px;
      padding-left: 12px;
      padding-right: 12px;
      padding-top: 17px;
      padding-bottom: 20px;
      .assoc-category-popup-image-wrapper {
        width: 100%;
        height: 152px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
  
      .assoc-category-popup-title {
        font-size: 20px;
        line-height: 20px;
        padding-top: 15px;
      }
  
      .assoc-category-popup-price {
        font-size: 20px;
        line-height: 20px;
        padding-bottom: 10px;
        padding-top: 6px;
      }
  
      .assoc-category-popup-description {
        font-size: 13px;
        line-height: 23px;
        padding-bottom: 16px;
        border-bottom-width:  3px;
        border-bottom-style:  solid;
      }
  
      .assoc-category-popup-category-item {
        width: 100%;
        padding-top: 17px;
      }
      .assoc-category-popup-category-title {
        font-size: 15px;
        line-height: 28px;
        padding-bottom: 3px;
      }
      .assoc-category-popup-category-desc {
        font-size: 13px;
        line-height: 13px;
        padding-bottom: 22px;
      }
      .assoc-category-popup-category-check {
        height: 45px;
        border-bottom-width: 3px;
        padding-left: 8px;
        .check_box_square__ {
          width: 11px;
          height: 11px;
          svg {
            width: 11px;
            height: 11px;
          }
        }
        .check_box_label__ {
          min-height: 11px;
          line-height: 11px;
          padding-left: 5px;
          font-size: 13px;
        }
        .check_box_container__ {
          padding-bottom: 0;
          margin-bottom: 0;
        }
      }
      .assoc-category-popup-add-btn {
        .assoc-category-popup-error {
          height: 26px;
          padding-bottom: 15px;
          font-size: 13px;
        }
        width: 100%;
        .generic_button_container {
          width: 100%;
          height: 63px;
        }
      }
  
    }
  }
}