.cookies_container {
  width: 100vw;
  height: 3.43vw;
  bottom: 0;
  left: 0;
  width: 100vw;
  position: fixed;
  display: flex !important;
  align-items: flex-end;
  box-sizing: border-box;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Trocchi";
  .cookies_text {
    width: 75%;
    text-align: left;
    font-size: 0.83vw;
    line-height: 1.5vw;
    margin-right: 1vw;
    a {
        color: unset;

    }
  }
  .cookies_button_cont {
    display: flex;
    align-items: center;
    .cookies_button:first-child {
      margin-right: 0.52px;
    }
    .cookies_link, 
    .cookies_button {
      width: auto;
      font-size: 0.7vw;
      padding: 0 2vw;
      cursor: pointer;
    }
    .cookies_link {
      text-decoration: underline;
    }
    .cookies_button {
      height: 2.66vw;
    }
  }
  div {
    box-sizing: border-box;
  }
}
@media (max-width: 1024px) {
    .cookies_container {
        height: auto;
        padding: 10px;
        .cookies_text {
            width: 84%;
            font-size: 10px;
            line-height: 15px;
            margin-right: 0;
            margin-bottom: 7px;
        }
        .cookies_button_cont {
          display: flex;
          .cookies_button:first-child {
            margin-left: 5px;
          }
          .cookies_link, 
          .cookies_button {
              width: auto;
              font-size: 10px;
          }
          .cookies_button {
            height: 45px;
          }
        }
      }
}
