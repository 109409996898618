.layout-loader-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("./../../assets/img/pattern_meniu.png") no-repeat center center;
  background-size: cover;
}
.flex_div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.layout-loader-container img {
  -webkit-animation: transformer 1.5s linear infinite;
  animation: transformer 1.5s linear infinite;
}
@keyframes transformer {
  0% {
    transform: scale(0.3);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(0.3);
  }
}

//
.menu-page-fade-in-anim-class-loader {
  opacity: 1;
  animation-name: menu_page_fade_in_anim_loader;
}
.menu-page-fade-out-anim-class-loader {
  opacity: 0;
  animation-name: menu_page_fade_out_anim_loader;
}
@keyframes menu_page_fade_in_anim_loader {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes menu_page_fade_out_anim_loader {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}