.feedback-container {
  * {
    box-sizing: border-box !important;
  }
  width: 100%;
  height: auto;
  font-family: "Trocchi";
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box !important;
  .feedback-title {
    font-size: 2.08vw;
    padding-top: 2.3vw;
    padding-bottom: 3.85vw;
    font-family: "Trocchi";
  }
  .feedback-icons-container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    height: 7vw;
    margin-top: 2.2vw;
    // margin-bottom: 0.56vw;
    position: relative;
    padding-bottom: 9.5vw;
    .feedback-error {
      font-family: "Trocchi";
      color: red;
      position: absolute;
      width: 100%;
      text-align: center;
      bottom: 0.7vw;
      left: 0;
    }
  }
  .feedback-paragraph {
    font-size: 1.56vw;
    line-height: 2vw;
    text-align: center;
    font-family: "Trocchi";
  }
  .feedback-button-container,
  .feedback-input-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .input-title {
    width: 32.77vw;
    margin-top: 1vw;
  }
  .feedback-input-container {
    margin-top: 1.2vw;
  }
  .feedback-input {
    width: 31.77vw;
    height: 5.2vw;
    font-size: 1.04vw;
    border-bottom: 0.26vw solid #bfbc8e;
    text-align: center;
    font-family: "Trocchi";
    background-color: transparent;
    border-top-width: 0;
    border-left-width: 0;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
    border-right-width: 0;
    outline: none;
  }
  .feedback-button-container {
    padding-top: 1.5vw;

  }

  &.second-phase {
    height: 25.3vw;
    .feedback-title {
      padding-top: 5vw;
      padding-bottom: 2vw;
    }
    .feedback-second-title {
      font-size: 2.08vw;
      font-family: "Trocchi";
    }
    .feedback-button-container {
      margin-top: 2.7vw;
    }
  }
}
.feedback-frame-popup {
  height: auto !important;
  max-height: unset !important;
  min-height: auto !important;
  .command_receipt_forbiden_container.pop_up__ {
    padding: 20px 0;
  }
}
@media screen and (max-width: 1224px) {
  .feedback-container {
    .feedback-error {
      font-size: 13px;
      bottom: 10px;
    }
  }
}
@media screen and (max-width: 1024px) {
  .feedback-container {
    height: auto;
    .feedback-title {
      font-size: 22px;
      padding-top: 50px;
      padding-bottom: 37px;
      padding-right: 10px;
      padding-left: 10px;
    }
    .feedback-icons-container {
      height: auto;
      margin-top: 25px;

      padding-bottom: 50px;
      .feedback-error {
        font-size: 12px;
        bottom: 40px;
        padding: 0 20px;
        
      }
    }
    .feedback-paragraph {
      font-size: 18px;
      line-height: 24px;
      padding: 0 10px;
    }
    .feedback-button-container,
    .feedback-input-container {
      width: 100%;
    }
    .input-title {
      width: auto;
      margin-top: 5px;
      padding-right: 20px;
      padding-left: 20px;
    }
    .feedback-input-container {
      margin-top: 20px;
    }
    .feedback-input {
      width: 220px;
      height: 80px;
      font-size: 12px;
      border-bottom: 4px solid #bfbc8e;
    }
    .feedback-button-container {
      padding-top: 20px;
    }
    .generic_button_container {
      width: 180px !important;
      height: 45px !important;
      font-size: 13px !important;
    }
    &.second-phase {
      min-height: auto;
      height: auto;
      padding: 70px 20px;
      .feedback-title {
        padding-top: 15px;
        padding-bottom: 43px;
      }
      .feedback-second-title {
        font-size: 22px;
      }
      .feedback-button-container {
        margin-top: 50px;
        
      }
    }


  }
}
@media only screen and (max-width: 357px) {
  .feedback-frame-popup {
    height: auto !important;
    max-height: unset !important;
    min-height: unset !important;
    .payment_conf_modal_container {
      height: auto !important;
      max-height: unset !important;
      min-height: unset !important;
      padding: 0 20px !important;
    }
  }
  .feedback-container {
    height: auto;
    padding: 70px 0;
  }
}