    .checkbox-menu-container {
        width: auto;
        display: flex;
        align-items: center;
        margin-right: 25px;
        .checkbox-menu-box {
            width: 24px;
            height: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            border-style: solid;
            border-width: 1px;
            border-color: #ff7f00;
            border-radius: 4px;
        }
        .svg-check-menu-item {
            width: 14px!important;
            height: 16px!important;
        }
        .checkbox-menu-label {
            padding-left: 10px;
            font-size: 12px;
            color: #6b6b6b;
            font-weight: 600;
        }
    }